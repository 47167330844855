import React from "react";
import { type DiscleGuess } from "../../api/Discle";
import styled, { keyframes } from "styled-components";
import Styles from "../../helpers/styles";

interface Props {
  guess: DiscleGuess
}

const ArrowAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(-20%);
  }
  75% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0%);
  }
`;

interface NameRowProps {
  isCorrect: boolean
}

const NameRow = styled.tr<NameRowProps>`
  td {
    font-weight: 700;
    padding: 10px;
    padding-bottom: 0px;
    color: var(--color-on-surface);
    text-align: center;

    ${({ isCorrect }) => isCorrect ? 'color: var(--color-positive);' : ''}
  }

  &.correct > td {
    background-color: var(--color-positive);
  }

  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

interface GuessCellProps {
  isCorrect: boolean
  isClose: boolean
}

const GuessCell = styled.td<GuessCellProps>`
  padding: 15px;
  border-bottom: 1px solid black;
  @media (max-width: ${Styles.mobileBreakpoint}) {
    padding: 5px;
  }
  background-color: var(--color-surface-two);

  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-on-surface);
    height: 45px;

    border: 2px solid var(--color-surface-two);

    ${({ isClose }) => isClose ? 'border-color: var(--color-warning);' : ''}
    ${({ isClose }) => isClose ? 'color: var(--color-warning);' : ''}

    ${({ isCorrect }) => isCorrect ? 'border-color: var(--color-positive);' : ''}
    ${({ isCorrect }) => isCorrect ? 'color: var(--color-positive);' : ''}

  }
`;

const NameCell = styled(GuessCell)`
  text-align: left;
  font-weight: 700;
  border-right: 1px solid black;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const RatingArrow = styled.span`
  animation: ${ArrowAnimation} 1.5s 0s linear infinite;
`;

const CompetitorGuessRow: React.FC<Props> = ({ guess }) => {
  const ratingArrow = guess.ratingDir === 'higher' ? '↑' : guess.ratingDir === 'lower' ? '↓' : '=';
  const pdgaNumberArrow = guess.pdgaNumberDir === 'higher' ? '↑' : guess.pdgaNumberDir === 'lower' ? '↓' : '=';

  return (
    <>
      <NameRow isCorrect={guess.correct} key={`${guess.id}-name`}><td colSpan={4}>{guess.name}</td></NameRow>
      <tr key={guess.id}>
        <NameCell isCorrect={guess.correct} isClose={false}><div>{guess.name}</div></NameCell>
        <GuessCell isCorrect={guess.manufacturerNameDir === 'equal'} isClose={false}><div>{guess.manufacturerName}</div></GuessCell>
        <GuessCell isCorrect={guess.ratingDir === 'equal'} isClose={guess.ratingClose}>
          <div>
            <span>{guess.rating}</span>
            <RatingArrow>{ratingArrow}</RatingArrow>
          </div>
        </GuessCell>
        <GuessCell isCorrect={guess.divisionDir === 'equal'} isClose={false}>
          <div>
            <span>{guess.division}</span>
          </div>
        </GuessCell>
        <GuessCell isCorrect={guess.pdgaNumberDir === 'equal'} isClose={guess.pdgaNumberClose}>
          <div>
            <span>{guess.pdgaNumber}</span>
            <RatingArrow>{pdgaNumberArrow}</RatingArrow>
          </div>
        </GuessCell>
      </tr>
    </>
  );
};

export default CompetitorGuessRow;
