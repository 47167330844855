import React from "react";

import Link from "./Link";

interface Props {
  signedIn?: boolean
  isAdmin?: boolean | null
}

const PageFooter: React.FC<Props> = ({ signedIn, isAdmin }) => {
  return (
    <div className="page-footer">
      <div className="pdga-data">
        <Link external href="https://www.pdga.com/">
          <div className="pdga-copyright">
            <div>{`Data © ${(new Date()).getFullYear()}`}</div>
            <div>{'PDGA'}</div>
          </div>
        </Link>
      </div>
      <div className="links-container">
        <div className="left-links">
          <div>
            © 2024 <Link external href="https://kchopp.com">Kendel Chopp</Link>
          </div>
          <Link external href="https://kendisc.com/terms-and-conditions">Terms</Link>
          <Link external href="https://kendisc.com/privacy-policy">Privacy Policy</Link>
        </div>
        <div className="right-links">
          <Link external href="https://kendisc.com/about">About</Link>
          <Link external href="https://kendisc.com/team">Team</Link>
          {signedIn && <Link href="/profile">Profile</Link>}
          {!signedIn && <Link href="/sign-in">Sign In</Link>}
          {signedIn && isAdmin && <Link href="/admin">Admin</Link>}
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
