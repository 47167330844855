import type EventCompetitorModel from "./EventCompetitorModel";
import Model from "./Model";
import PickModel from "./PickModel";
import Request from "./Request";
import RequestAndParse from "./RequestAndParse";

interface LeaderboardEntry {
  userId: string
  username: string
  points: number
  mpoPoints: number
  fpoPoints: number
}

export type LeaderboardType = LeaderboardEntry[];

interface EventCompetitorList {
  mpo: EventCompetitorModel[] | null
  fpo: EventCompetitorModel[] | null
}

interface PickSets {
  mpo: PickModel[] | null
  fpo: PickModel[] | null
}

class EventModel extends Model {
  static apiPath = '/events';
  static _models = {};
  static _hasReadAll = false;
  static _defaultParams = {
    eventCompetitors: {
      mpo: null,
      fpo: null
    },
    picks: {
      mpo: null,
      fpo: null
    },
    userPicks: {}
  };

  static DIVISIONS = {
    MPO: 'mpo',
    FPO: 'fpo'
  };

  public leaderboard?: LeaderboardType | null;
  public eventCompetitors: EventCompetitorList;
  public picks: PickSets;
  public userPicks: Record<string, PickSets>;
  public endDate: string;
  public startDate: string;
  public name: string;
  public presentedBy: string;
  public location: string;
  public pdgaEventId: string;
  public updatedAt: string;

  async getLeaderboard({ force = false } = {}): Promise<LeaderboardType> {
    if (this.leaderboard && this.leaderboard.length > 0 && !force) {
      return this.leaderboard;
    }

    const { leaderboard } = await Request(`${(this.constructor as typeof EventModel).apiPath}/${this.id}/leaderboard`);
    this.leaderboard = leaderboard;

    return leaderboard;
  }

  async readEventCompetitors(division: string): Promise<EventCompetitorModel[]> {
    if (this.eventCompetitors[division]) {
      return this.eventCompetitors[division];
    }

    const { eventcompetitors: eventCompetitors } = await RequestAndParse(`/events/${this.id}/competitors/${division}`);
    this.eventCompetitors[division] = eventCompetitors;
    return eventCompetitors;
  }

  async getPicks(division: string): Promise<PickModel[]> {
    if (this.picks[division]) {
      return this.picks[division];
    }

    const { picks } = await RequestAndParse(`${(this.constructor as typeof EventModel).apiPath}/${this.id}/picks/${division}`);
    this.picks[division] = picks;

    return this.picks[division];
  }

  async getUserPicks({ userId, force = false }: { userId: string, force?: boolean }): Promise<PickSets> {
    if (this.userPicks[userId] && !force) {
      return this.userPicks[userId];
    }

    const { picks } = await RequestAndParse(`${(this.constructor as typeof EventModel).apiPath}/${this.id}/picks/all/${userId}`);
    this.userPicks[userId] = picks;

    return this.userPicks[userId];
  }

  async makePick(division: string, eventCompetitorId: string): Promise<any> {
    const data = await Request(`/events/${this.id}/picks`, {
      method: 'POST',
      body: {
        eventCompetitorId,
        division
      }
    });

    if (this.picks[division]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.picks[division] = [...this.picks[division], PickModel.findOrCreate(data).id];
    }
  }

  async deletePick(eventCompetitorId: string, division: string): Promise<any> {
    await Request(`/events/${this.id}/picks`, {
      method: 'DELETE',
      body: {
        eventCompetitorId
      }
    });

    if (this.picks[division]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const picksCopy: PickModel[] = Array.from(this.picks[division]);
      const index = picksCopy.findIndex((pick) => {
        return pick.eventCompetitorId === eventCompetitorId;
      });

      if (index !== -1) {
        picksCopy.splice(index, 1);
        this.picks[division] = picksCopy;
      }
    }
  }

  async getEventCompetitors(): Promise<Record<string, EventCompetitorModel>> {
    const data = await Request(`/events/${this.id}/event-competitors`);

    return Model.parseFetchData(data);
  }

  get formattedEndDate(): string {
    if (!this.endDate) {
      return "";
    }

    return new Date(this.endDate).toLocaleString('en-US', { day: 'numeric', month: 'long' });
  }

  get formattedStartDate(): string {
    if (!this.startDate) {
      return "";
    }

    return new Date(this.startDate).toLocaleString('en-US', { day: 'numeric', month: 'long' });
  }

  get isPastEvent(): boolean {
    if (!this.endDate) {
      return false;
    }

    return new Date(this.endDate) < new Date();
  }

  get isCurrentEvent(): boolean {
    return !this.isPastEvent && !this.isUpcomingEvent;
  }

  get isUpcomingEvent(): boolean {
    if (!this.startDate) {
      return false;
    }

    return new Date(this.startDate) > new Date();
  }

  get canMakePicks(): boolean {
    return this.isUpcomingEvent;
  }

  get updatedAtString(): string {
    if (!this.updatedAt) {
      return "";
    }

    return new Date(this.updatedAt).toLocaleString('en-US', {
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: 'numeric'
    });
  }
}

Model.registerModel('Events', EventModel);

export default EventModel;
