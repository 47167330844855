import React from 'react';
import styled from 'styled-components';
import Styles from '../helpers/styles';

const Header = styled.h1`
  color: ${Styles.colors.font};
  font-size: 4em;
`;

const SubHeader = styled.h2`
  color: ${Styles.colors.font};
  font-size: 1.8em;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotFound: React.FC = () => {
  return (
    <Container>
      <Header>Page Not Found</Header>
      <SubHeader>Oops we missed the putt!</SubHeader>
    </Container>
  );
};

export default NotFound;
