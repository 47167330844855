import React from "react";
import Icon, { ICON_TYPES } from "../../../components/Icon";
import styled, { css } from "styled-components";
import Styles from "../../../helpers/styles";
import Link from "../../../components/Link";
import User from "../../../api/User";
import { useLeagueStore } from "../../../stores";
import switchPath from "../../../helpers/switchPath";

interface Props {
  setExpanded: () => void
  title: string
  expanded: boolean
  signedIn?: boolean
}

interface ScrolledProps {
  scrolled: boolean
}

const ScrolledStyles = css`
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3), 0px 1px 3px 1px rgba(0,0,0, 0.15);
  background-color: var(--color-background);
`;

const HeaderContainer = styled.div<ScrolledProps>`
  background-color: rgba(var(--color-background), 0.6);
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px;
  height: 50px;
  z-index: 100;
  display: flex;
  flex-grow: 0;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-background);
  transition: background-color 0.2s ease-in;

  ${({ scrolled }) => scrolled ? ScrolledStyles : ''}
`;

const HamburgerIcon = styled(Icon)`
  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

interface ExpandedProps {
  expanded: boolean
}

const SettingsIcon = styled(Icon)<ExpandedProps>`
  ${({ expanded }) => expanded ? 'transform: rotate(90deg);' : ''}
  transition: transform 0.3s ease;
`;

const LeftContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const TitleScrolledStyles = css`
  opacity: 1;
  transform: translateY(0);
`;

const Title = styled.h1<ScrolledProps>`
  color: var(--color-white);
  // opacity: 0;
  // transform: translateY(20px);
  // transition: all 0.3s ease;

  ${({ scrolled }) => scrolled ? TitleScrolledStyles : ''}
`;

const ProfileDropdown = styled.div`
  background-color: var(--color-surface);
  width: 256px;
  position: fixed;
  top: 50px;
  right: 10px;

  display: flex;
  flex-direction: column;
  row-gap: 0px;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 2px 8px 16px 2px rgba(0,0,0,0.4);
`;

const SharedStyles = css`
  width: 100%;
  padding: 10px 10px;
  border-radius: 0px;
  box-sizing: border-box;
  color: var(--color-on-surface) !important;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    background-color: var(--color-on-surface-hover);
  }
  text-align: left;
  font-size: 16px;
  font-weight: 500;
`;

const SettingsLink = styled(Link)`
  ${SharedStyles}
`;

const SignOutButton = styled.button`
  background-color: inherit;
  border: none;

  ${SharedStyles}
`;

const FantasyHeader: React.FC<Props> = ({ setExpanded, title, signedIn }) => {
  const clearLeagues = useLeagueStore((state) => state.clear);
  const [profileDropdownOpen, setProfileDropdownOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = (): void => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const handleClick = (): void => {
    setProfileDropdownOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const scrolled = scrollPosition > 20;

  const openProfile = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const signOut = async (): Promise<any> => {
    try {
      await User.signOut();
    } finally {
      clearLeagues();
      switchPath('/');
    }
  };

  const signIn = (): void => {
    switchPath('/sign-in');
  };

  const signButton = signedIn ? (
    <SignOutButton onClick={signOut}>{"Sign Out"}</SignOutButton>
  ) : (
    <SignOutButton onClick={signIn}>{"Sign In"}</SignOutButton>
  );

  const profileDropdown = profileDropdownOpen ? (
    <ProfileDropdown>
      {signedIn && <SettingsLink href="/profile">Settings</SettingsLink>}
      {signButton}
    </ProfileDropdown>
  ) : null;

  return (
    <HeaderContainer scrolled={ scrolled}>
      <LeftContainer>
        <HamburgerIcon type={ICON_TYPES.HAMBURGER} size={32} onClick={setExpanded} />
        <Title scrolled={scrolled}>{title}</Title>
      </LeftContainer>
      <div className="center">
      </div>
      <div className="right">
        <SettingsIcon expanded={profileDropdownOpen} type={ICON_TYPES.SETTINGS} size={32} onClick={openProfile} />
        {profileDropdown}
      </div>
    </HeaderContainer>
  );
};

export default FantasyHeader;
