import Model from "./Model";
import Request from "./Request";

function validateThreeDecimalPlaces(number: number | string): boolean {
  const regex = /^\d+(\.\d{1,3})?$/;
  return regex.test(String(number));
}

class LeagueEventModel extends Model {
  static apiPath = '/league-events';
  static _requireId = false;

  public endDate: string;
  public startDate: string;
  public name: string;
  public presentedBy: string;
  public location: string;
  public pdgaEventId: string;
  public updatedAt: string;
  public tier: string;
  public leagueId: string;
  public eventId: string;
  public status: string;
  public isUniversal: boolean;
  public multiplier: number;
  public enabled: boolean;

  get formattedStartDate(): string {
    if (!this.startDate) {
      return "";
    }

    return new Date(this.startDate).toLocaleString('en-US', { day: 'numeric', month: 'long' });
  }

  async flipEnabled(): Promise<LeagueEventModel> {
    const newEnabled = !this.enabled;
    await Request(`/leagues/${this.leagueId}/league-events/${this.eventId}`, {
      method: 'PUT',
      body: {
        enabled: newEnabled
      }
    });

    this.enabled = newEnabled;
    return this;
  }

  async updateMultiplier(multiplier: number): Promise<LeagueEventModel> {
    if (isNaN(multiplier) || !validateThreeDecimalPlaces(multiplier) || multiplier > 25 || multiplier < 0) {
      return this;
    }

    await Request(`/leagues/${this.leagueId}/league-events/${this.eventId}`, {
      method: 'PUT',
      body: {
        multiplier
      }
    });

    this.multiplier = multiplier;

    return this;
  }
}

Model.registerModel('LeagueEvents', LeagueEventModel);

export default LeagueEventModel;
