const switchPath = (newPath: string): void => {
  // Update the pathname
  window.history.pushState({}, "", newPath);

  // Tell App to re-render
  window.dispatchEvent(new PopStateEvent('popstate'));

  // Scroll to top
  window.scrollTo(0, 0);
};

export default switchPath;
