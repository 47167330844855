import Model from "./Model";
import Request from "./Request";

type GuessDirection = "equal" | "higher" | "lower";
export interface Guess {
  name: string
  id: string
  correct: boolean
  rating: number
  ratingDir: GuessDirection
  ratingClose: boolean
  pdgaNumber: string
  pdgaNumberDir: GuessDirection
  pdgaNumberClose: boolean
  manufacturerName: string
  manufacturerNameDir: "equal" | "different"
  manufacturerNameClose: boolean
  division: 'MPO' | 'FPO' | 'Unknown'
  divisionDir: "equal" | "different"
}

class CompetitorModel extends Model {
  static _models = {};
  static _hasReadAll = false;

  public name: string;
  public rating: number;

  static async guessCompetitor(competitorId: string): Promise<Guess | null> {
    if (!competitorId) {
      return null;
    }

    const result = await Request(`/competitors/guess/${competitorId}`);

    return result;
  }

  static async getAnswer(): Promise<Guess> {
    return Request('/competitors/answer');
  }

  static async searchCompetitors(name?: string): Promise<CompetitorModel[]> {
    if (!name) {
      return [];
    }

    const result = await Request(`/competitors?name=${name}`);
    return result.competitors;
  }
}

Model.registerModel('Competitors', CompetitorModel);

export default CompetitorModel;
