import React from 'react';

import isPropValid from '@emotion/is-prop-valid';

import initializeApp from './api/initializeApp';
import User from './api/User';

import Alert, { type AlertType } from './helpers/alerts';
import AlertComponent from './components/Alert';

import Fantasy from './pages/authenticated/fantasy/Fantasy';

import Logo from './components/logo';
import classNames from './helpers/classNames';
import type LeagueModel from './api/LeagueModel';
import { LeaguesContext } from './contexts';
import EmailVerified from './pages/EmailVerified';
import singletons from './api/singletons';
import styled, { StyleSheetManager } from 'styled-components';
import switchPath from './helpers/switchPath';

const CloakedBanner = styled.div`
  width: 100%;
  background-color: yellow;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
`;

const NON_AUTH_ROUTES = [
  'sign-in',
  'sign-up',
  'password-reset'
];

const PUBLIC_ROUTES = [
  'discle',
  'mcbirdle'
];

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any): boolean {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const App = (): React.ReactElement => {
  const [appInitialized, setAppInitialized] = React.useState(false);
  const [signedIn, setSignedIn] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [fullPath, setFullPath] = React.useState(window.location.pathname);
  const [alerts, setAlerts] = React.useState<AlertType[]>([]);
  const [leagues, setLeagues] = React.useState<Record<string, LeagueModel>>({});

  React.useEffect(() => {
    window.addEventListener('popstate', () => {
      setFullPath(window.location.pathname);
    });

    initializeApp(({ signedIn: isSignedIn, emailVerified: isEmailVerified }) => {
      if (isSignedIn) {
        User.getUser().then(() => {
          setAppInitialized(true);
          setSignedIn(isSignedIn);
          setEmailVerified(isEmailVerified);
        }).catch((error) => {
          console.error(error);
          window.alert('Something went wrong! Please refresh');
        });
      } else {
        setAppInitialized(true);
        setSignedIn(isSignedIn);
        setEmailVerified(isEmailVerified);
      }
    });

    Alert.onAlertChange = (newAlerts) => {
      setAlerts(Array.from(newAlerts));
    };
  }, []);

  React.useEffect(() => {
    if (!appInitialized) {
      return;
    }
    const splitPath = fullPath.split('/');
    const relevantPath = splitPath[1];

    if (!signedIn) {
      if (!NON_AUTH_ROUTES.includes(relevantPath) && !PUBLIC_ROUTES.includes(relevantPath)) {
        switchPath('/sign-in');
      }
    }

    if (signedIn) {
      if (relevantPath === '' || NON_AUTH_ROUTES.includes(relevantPath)) {
        switchPath('/fantasy');
      }
    }
  }, [fullPath, signedIn, emailVerified, appInitialized]);

  const content = React.useMemo(() => {
    if (!appInitialized) {
      return <Logo size={100} spinning />;
    }
    const splitPath = fullPath.split('/');
    switch (splitPath[1]) {
      case '':
        return null;
      case 'email-verified':
        return <EmailVerified />;
      default:
        return (
          <Fantasy
            isAdmin={User.user && User.user.isAdmin}
            signedIn={signedIn}
            emailVerified={emailVerified}
            path={fullPath}
          />
        );
    }
  }, [fullPath, signedIn, emailVerified, appInitialized]);

  const alertComponents = React.useMemo(() => {
    return alerts.map((alert, index) => {
      return <AlertComponent key={index} message={alert.message} icon={alert.icon} color={alert.color} />;
    });
  }, [alerts]);

  const appClasses = classNames('app', { loading: !appInitialized });

  const unCloak = (): void => {
    window.localStorage.removeItem('cloakedUserId');
    window.location.href = "/";
  };
  const cloakedBanner = singletons.cloakedUserId ? (
    <CloakedBanner onClick={unCloak}>You are currently cloaked!</CloakedBanner>
  ) : null;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className={appClasses}>
        <LeaguesContext.Provider value={[leagues, setLeagues]}>
          {cloakedBanner}
          <div className="alerts">
            {alertComponents}
          </div>
          { content }
        </LeaguesContext.Provider>
      </div>
    </StyleSheetManager>
  );
};

export default App;
