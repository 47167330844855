import React from "react";
import Styles from "../../helpers/styles";
import styled from "styled-components";
import LeagueModel from "../../api/LeagueModel";
import switchPath from "../../helpers/switchPath";
import Link from "../../components/Link";
import GenericTable from "./fantasy/league/GenericTable";
import { useLeagueStore } from "../../stores";

const Header = styled.h1`
  color: ${Styles.colors.white};
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Styles.colors.border};
  padding: 5px;
  border-radius: 3px;
  background-color: ${Styles.colors.page};

  &:focus-within {
    border-color: ${Styles.colors.emphasis};
  }
`;

const NameLabel = styled.label`
  color: ${Styles.colors.font};
  font-size: 12px;
  width: 100%;
  padding-bottom: 8px;
`;

const NameInput = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${Styles.colors.font};
  outline: none;
  font-size: 18px;
`;

const StyledLink = styled(Link)`
  margin-top: 10px;
`;

const TableContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 750px;
  align-items: flex-start;
`;

const SearchHeader = styled.h2`
  color: ${Styles.colors.font};
`;

const LeagueSearch: React.FC = () => {
  const [leagueName, setLeagueName] = React.useState("");
  const [leagues, setLeagues] = React.useState<LeagueModel[]>([]);
  const hasLeague = useLeagueStore((state) => state.hasLeague);

  React.useEffect(() => {
    const search = async (): Promise<any> => {
      const leagueResults = await LeagueModel.search(leagueName);
      setLeagues(leagueResults);
    };
    search();
  }, [leagueName]);

  const leagueRows = React.useMemo(() => {
    return leagues.filter(league => !hasLeague(league.id)).map((league) => {
      const onClick = (): void => {
        switchPath(`/fantasy/leagues/join/${league.id}`);
      };

      return {
        onClick,
        cells: [
          league.name,
          league.playerCount ?? 0
        ],
        key: league.id
      };
    });
  }, [leagues]);

  const headers = [
    "Name",
    "# Members"
  ];

  return (
    <Page>
      <Header>Join a League</Header>
      <NameContainer>
        <NameLabel htmlFor="league-name">{"League Search"}</NameLabel>
        <NameInput
          id="league-name"
          value={leagueName}
          onChange={(e) => {
            if (e.target.value.length > 256) {
              return;
            }
            setLeagueName(e.target.value);
          }}
          required
        />
      </NameContainer>
      <StyledLink href="/fantasy/leagues/create">{"Create a New League"}</StyledLink>
      <TableContainer>
        <SearchHeader>{'Search Results'}</SearchHeader>
        <GenericTable
          headers={headers}
          rows={leagueRows}
        />
      </TableContainer>
    </Page>
  );
};

export default LeagueSearch;
