import React from 'react';
import _ from 'lodash';

import EventModel from '../../../api/EventModel';

import PicksTable from './PicksTable';
import Leaderboard from './Leaderboard';

const CONST_TABS = [{
  key: "mpo",
  name: "MPO Picks"
}, {
  key: "fpo",
  name: "FPO Picks"
}];

const upcomingTabs = [];

const otherTabs = [{
  key: "leaderboard",
  name: "Leaderboard"
}];

const getTabs = (canMakePick): Array<{ key: string, name: string }> => {
  return [
    ...(canMakePick ? [] : otherTabs),
    ...CONST_TABS,
    ...(canMakePick ? upcomingTabs : [])
  ];
};

interface TabProps {
  selected?: boolean
  name: string
  onClick: () => void
}

const Tab: React.FC<TabProps> = ({ selected, name, onClick }) => {
  const className = selected ? "selected tab" : "tab";
  return (
    <div className={className} onClick={onClick}>
      {name}
    </div>
  );
};

interface EventTableProps {
  event: EventModel
  canMakePick?: boolean
}

const EventTable: React.FC<EventTableProps> = ({ event, canMakePick }) => {
  const params = new URLSearchParams(window.location.search);
  const TABS = React.useMemo(() => {
    return getTabs(canMakePick);
  }, [canMakePick]);
  const tabParam = params.get('tab');
  const tabIndex = tabParam ? _.findIndex(TABS, tab => tab.key === tabParam) : -1;
  const [selectedTab, setSelectedTab] = React.useState(tabIndex === -1 ? 0 : tabIndex);

  React.useEffect(() => {
    if (tabIndex === -1) {
      const url = new URL(window.location.href); // Was window.location
      url.searchParams.set('tab', TABS[0].key);
      window.history.replaceState(null, '', url.toString());
    }
  }, []);

  const setTab = (index): void => {
    setSelectedTab(index);
    const url = new URL(window.location.href);
    url.searchParams.set('tab', TABS[index].key);
    window.history.pushState(null, '', url.toString());
  };

  const tabs = TABS.map((tab, index) => (
    <Tab
      key={tab.key}
      selected={selectedTab === index}
      name={tab.name}
      onClick={() => setTab(index)}
    />
  ));

  let content;

  const tabKey = React.useMemo(() => {
    if (tabIndex >= 0) {
      return TABS[selectedTab].key;
    } else {
      return TABS[0].key;
    }
  }, [selectedTab, TABS]);

  switch (tabKey) {
    case 'mpo':
      content = <PicksTable canMakePick={canMakePick} division={EventModel.DIVISIONS.MPO} event={event} />;
      break;
    case 'fpo':
      content = <PicksTable canMakePick={canMakePick} division={EventModel.DIVISIONS.FPO} event={event} />;
      break;
    case 'leaderboard':
      content = <Leaderboard event={event} />;
      break;
  }

  return (
    <div className="event-table">
      <div className="tabs">
        {tabs}
      </div>
      <div className="content">
        {content}
      </div>
    </div>
  );
};

export default EventTable;
