import React from 'react';
import classNames from '../helpers/classNames';

import Alert from '../helpers/alerts';

import DiscleApi, { type DiscleCompetitor, type DiscleGuess } from '../api/Discle';

import Popup from '../components/Popup';
import styled from 'styled-components';
import Styles from '../helpers/styles';
import GuessesTable from './discle/GuessesTable';
import Button from '../components/Button';

const MainHeader = styled.h1`
  color: ${Styles.colors.font};
`;

const SubHeader = styled.h2`
  color: ${Styles.colors.font};
`;

interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const CountdownTimer: React.FC = () => {
  const calculateTimeLeft = (): TimeLeft => {
    const now = new Date();
    const addDay = now.getUTCHours() >= 4;
    const etMidnight = new Date(Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      addDay ? now.getUTCDate() + 1 : now.getUTCDate(),
      4,
      0,
      0,
      0
    ));
    const difference = etMidnight.valueOf() - now.valueOf();

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000)
    };
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
    </>
  );
};

interface Props {
  isAdmin?: boolean | null
}

const Discle: React.FC<Props> = ({ isAdmin }) => {
  const date = new Date(); // Get the current date and time
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  const easternTimeString = date.toLocaleString('en-US', options).replaceAll('/', '-');
  const guessDate = localStorage.getItem('lastGuess');
  if (guessDate !== easternTimeString) {
    localStorage.removeItem('guesses');
  }
  const initialGuesses = JSON.parse(localStorage.getItem("guesses") ?? "[]") || [];
  const numInitialGuesses = initialGuesses.length;
  const initialIsDone = numInitialGuesses >= 8 || (
    numInitialGuesses > 0 && initialGuesses[numInitialGuesses - 1].correct
  );
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<DiscleCompetitor[]>([]);
  const [focused, setFocused] = React.useState(false);
  const [guesses, setGuesses] = React.useState<DiscleGuess[]>(initialGuesses as DiscleGuess[]);
  const [isGuessing, setIsGuessing] = React.useState(false);
  const [isDone, setIsDone] = React.useState(initialIsDone);
  const [showModal, setShowModal] = React.useState(false);
  const [answer, setAnswer] = React.useState<null | DiscleGuess>(null);
  const [allCompetitors, setAllCompetitors] = React.useState<DiscleCompetitor[] | null>(null);

  const guessIds = React.useMemo(() => {
    return guesses.map((guess) => guess.id);
  }, [guesses]);

  React.useEffect(() => {
    const loadSearchResults = async (): Promise<any> => {
      if (!searchQuery) {
        setSearchResults([]);
      } else if (allCompetitors) {
        const lowercaseQuery = searchQuery.toLowerCase();
        const competitors = allCompetitors.filter((competitor) => {
          return competitor.name.toLowerCase().includes(lowercaseQuery) && !guessIds.includes(competitor.id);
        }).slice(0, 5);
        setSearchResults(competitors);
      }
    };

    loadSearchResults();
  }, [searchQuery]);

  React.useEffect(() => {
    const loadCompetitors = async (): Promise<any> => {
      if (!allCompetitors) {
        const fetchedCompetitors = await DiscleApi.getGuessableCompetitors();
        setAllCompetitors(fetchedCompetitors);
      }
    };

    const loadAnswer = async (): Promise<any> => {
      if (!initialIsDone) {
        return;
      }

      if (guesses[guesses.length - 1].correct) {
        setAnswer(guesses[guesses.length - 1]);
      } else {
        const fetchedAnswer = await DiscleApi.getAnswer();
        setAnswer(fetchedAnswer);
      }

      setShowModal(true);
    };

    const loadAll = async (): Promise<any> => {
      await loadCompetitors();
      await loadAnswer();
    };

    loadAll();
  }, []);

  const guessPlayer = async (player: DiscleCompetitor): Promise<any> => {
    if (isGuessing || isDone) {
      return;
    }
    setIsGuessing(true);
    try {
      const fetchedPlayer = await DiscleApi.guessCompetitor(player.id);

      const newGuesses: DiscleGuess[] = [
        ...guesses
      ];
      if (fetchedPlayer) {
        newGuesses.push(fetchedPlayer);
      }
      localStorage.setItem('lastGuess', easternTimeString);
      localStorage.setItem("guesses", JSON.stringify(newGuesses));
      setGuesses(newGuesses);
      setSearchQuery("");
      if (fetchedPlayer?.correct) {
        setAnswer(null);
      }
      if (fetchedPlayer?.correct || newGuesses.length >= 8) {
        if (fetchedPlayer?.correct) {
          setAnswer(fetchedPlayer);
        } else {
          const fetchedAnswer = await DiscleApi.getAnswer();
          setAnswer(fetchedAnswer);
        }
        setIsDone(true);
        setShowModal(true);
      }
    } finally {
      setIsGuessing(false);
    }
  };

  const submitGuess = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();

    if (searchResults.length > 0) {
      guessPlayer(searchResults[0]);
    }
  };

  const renderedSearchResults = React.useMemo(() => {
    return searchResults.map((searchResult) => (
      <div
        className="result"
        key={searchResult.id}
        tabIndex={0}
        onClick={() => {
          guessPlayer(searchResult);
          setFocused(false);
        }}
      >
        {searchResult.name}
      </div>
    ));
  }, [searchResults]);

  const dropdownContent = React.useMemo(() => {
    if (!focused) {
      return null;
    }
    if (renderedSearchResults.length === 0) {
      return null;
    }

    return (
      <div className="dropdown-content">
        {renderedSearchResults}
      </div>
    );
  }, [renderedSearchResults, focused]);

  const guessedPlayers = <GuessesTable isDone={isDone} guesses={guesses} />;

  const reset = (): void => {
    setGuesses([]);
    setIsDone(false);
    localStorage.removeItem('guesses');
  };

  const placeholderText = React.useMemo(() => {
    if (isDone) {
      if (guesses[guesses.length - 1].correct) {
        return 'Correct!';
      } else {
        return 'Try again tomorrow';
      }
    } else {
      return `Guess ${guesses.length + 1} of 8`;
    }
  }, [guesses, isDone]);

  const popupContent = React.useMemo(() => {
    if (!showModal) {
      return null;
    }

    const failed = !guesses[guesses.length - 1].correct;

    const firstLine = failed ? 'Sorry, the correct answer is' : 'Correct! The answer is';
    const thirdLine = failed ? 'Try again tomorrow' : `You got it in ${guesses.length} guess${guesses.length === 1 ? '' : 'es'}.`;

    return (
      <div className="done-popup-conent">
        <div>{firstLine}</div>
        <h3>{answer?.name}</h3>
        <div>{thirdLine}</div>
        <hr className="divider" />
        <div>{'New McBirdle unlocks in'}</div>
        <div><CountdownTimer /></div>
      </div>
    );
  }, [showModal]);

  const shareResults = (): void => {
    const numGuesses = guesses[guesses.length - 1].correct ? guesses.length : 'X';
    let results = `McBirdle ${numGuesses}/8`;
    guesses.forEach((guess) => {
      results += '\n';
      // Name
      results += guess.correct ? '🟩' : '⬛️';
      // Sponsor
      results += guess.manufacturerNameDir === 'equal' ? '🟩' : '⬛️';
      // Rating
      results += guess.ratingDir === 'equal' ? '🟩' : guess.ratingClose ? '🟨' : '⬛️';
      // Division
      results += guess.divisionDir === 'equal' ? '🟩' : '⬛️';
      // PDGA Number
      results += guess.pdgaNumberDir === 'equal' ? '🟩' : guess.pdgaNumberClose ? '🟨' : '⬛️';
    });

    if (navigator.share) {
      navigator.share({
        title: 'McBirdle Results',
        text: results
      });
    } else {
      navigator.clipboard.writeText(results);
      Alert.addAlert('Copied to clipboard ✅', { color: 'emphasis' });
    }
  };

  const donePopup = showModal ? (
    <Popup
      content={popupContent}
      onClose={() => {
        setShowModal(false);
      }}
      successLabel="Share"
      onSuccess={shareResults}
    />
  ) : null;

  const discleClasses = classNames('discle');

  return (
    <div className={discleClasses}>
      {donePopup}
      <div className="page-content">
        {isAdmin && <button className="reset-button" onClick={reset}>{"RESET"}</button> }
        {isAdmin && <div>{'*This button is for testing purposes only and will be removed'}</div>}
        <MainHeader>{"McBirdle"}</MainHeader>
        <SubHeader>{"Disc Golfer Guessing Game"}</SubHeader>
        <form
          className="search-form"
          tabIndex={-1}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setFocused(false);
            }
          }}
          // onKeyDown={(e) => {
          //   if (e.key === 'ArrowUp') {
          //   } else if (e.key === 'ArrowDown') {
          //   }
          // }}
          onSubmit={submitGuess}
        >
          <div className="dropdown">
            <input
              disabled={isGuessing || isDone}
              placeholder={placeholderText}
              className="name-input"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onFocus={() => setFocused(true)}
            />
            {dropdownContent}
          </div>
        </form>
        {isDone && <Button onClick={() => setShowModal(true)} label="Reveal Answer" />}
        {guessedPlayers}
      </div>
    </div>
  );
};

export default Discle;
