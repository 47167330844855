import React from "react";
import GenericTable from "../fantasy/league/GenericTable";
import Admin, { type AdminCompetitorModel } from "../../../api/Admin";
import ManufacturerModel from "../../../api/ManufacturerModel";

const AdminCompetitors: React.FC = () => {
  const [competitors, setCompetitors] = React.useState<AdminCompetitorModel[]>([]);
  const [manufacturers, setManufacturers] = React.useState<Record<string, ManufacturerModel>>({});

  // const [isSaving, setIsSaving] = React.useState(false);
  const [savingItems, setSavingItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    const loadCompetitors = async (): Promise<any> => {
      const readManufacturers = await ManufacturerModel.fetchAll() as Record<string, ManufacturerModel>;
      setManufacturers(readManufacturers);
      const readCompetitors = await Admin.getBackfillCompetitors();
      setCompetitors(readCompetitors);
    };

    loadCompetitors();
  }, []);

  const rows = React.useMemo(() => {
    return Object.values(competitors).map((competitor) => {
      const manufacturer = manufacturers[competitor.manufacturerId];

      const saveCompetitor = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<any> => {
        setSavingItems(curr => [...curr, competitor.id]);
        await Admin.updateCompetitorManufacturer(competitor.id, event.target.value);
        competitor.manufacturerId = event.target.value;
        setSavingItems(curr => {
          const currCopy = Array.from(curr);
          currCopy.splice(curr.indexOf(competitor.id), 1);
          return currCopy;
        });
      };

      const selector = (
        <select
          required
          disabled={savingItems.includes(competitor.id)}
          defaultValue={ manufacturer?.id ?? undefined }
          onChange={saveCompetitor}
          value={competitor.manufacturerId ?? "no-manufacturer"}
        >
          <option value="no-manufacturer" disabled hidden={Boolean(competitor.manufacturerId)}>Please Choose...</option>
          {Object.values(manufacturers).map((manufacturer) => {
            return (
              <option key={manufacturer.id} value={manufacturer.id}>
                {manufacturer.name
              }</option>
            );
          })}
        </select>
      );

      return {
        cells: [
          competitor.id,
          competitor.name,
          selector
        ],
        key: competitor.id
      };
    });
  }, [competitors]);

  const headers = [
    "ID",
    "Name",
    "Manufacturer"
  ];

  return (
    <div>
      <GenericTable
        headers={headers}
        rows={rows}
      />
    </div>
  );
};

export default AdminCompetitors;
