import React from "react";
import styled from "styled-components";
import AdminUsers from "./AdminUsers";
import AdminEvents from "./AdminEvents";
import TabBar from "../../../components/TabBar";
import AdminManufacturers from "./AdminManufacturers";
import AdminCompetitors from "./AdminCompetitors";

const AdminContainer = styled.div``;

const TABS = {
  events: {
    id: 'events',
    name: 'Events'
  },
  users: {
    id: 'users',
    name: 'Users'
  },
  manufacturers: {
    id: 'manufacturers',
    name: 'Manufacturers'
  },
  competitors: {
    id: 'competitors',
    name: 'Competitors'
  }
};

const Admin: React.FC = () => {
  const [tab, setTab] = React.useState("events");

  const content = React.useMemo(() => {
    if (tab === 'events') {
      return <AdminEvents />;
    } else if (tab === 'users') {
      return <AdminUsers />;
    } else if (tab === 'manufacturers') {
      return <AdminManufacturers />;
    } else if (tab === 'competitors') {
      return <AdminCompetitors />;
    }
    return null;
  }, [tab]);

  return (
    <AdminContainer className="admin">
      <TabBar
        tabs={TABS}
        tab={tab}
        setTab={(tab: string) => setTab(tab)}
      />
      {content}
    </AdminContainer>
  );
};

export default Admin;
