import * as React from 'react';

import Alert from '../helpers/alerts';
import switchPath from '../helpers/switchPath';

import Link from '../components/Link';
import Popup from '../components/Popup';

import User from '../api/User';
import classNames from '../helpers/classNames';

const InputError: React.FC<{ error?: string | null }> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div className="input-error">{error}</div>
  );
};

interface AuthenticationProps {
  signUp?: boolean
  resetPassword?: boolean
  signedIn?: boolean
}

const Authentication: React.FC<AuthenticationProps> = ({ signUp, resetPassword, signedIn }) => {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmError, setConfirmError] = React.useState<string | null>(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (signedIn) {
      switchPath('/');
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();

    if (signUp && password !== confirmPassword) {
      setConfirmError('Passwords do not match.');
      return;
    }

    if (signUp && password.length < 6) {
      setPasswordError('Your password must be at least 6 characters long');
      return;
    }

    try {
      setEmailError(null);
      setPasswordError(null);
      setConfirmError(null);
      setIsLoading(true);

      if (resetPassword) {
        await User.resetPassword(email);
      } else {
        const authMethod = signUp ? User.signUp : User.signIn;
        await authMethod(email, password);
      }

      if (signUp || resetPassword) {
        setShowPopup(true);
      } else {
        switchPath('/');
      }
    } catch (error) {
      const definedError = User.DEFINED_ERRORS[error.code];
      if (definedError) {
        if (definedError.element === 'email') {
          setEmailError(definedError.message);
        } else if (definedError.element === 'password') {
          setPasswordError(definedError.message);
        } else {
          Alert.addAlert(definedError.message);
        }
      } else {
        Alert.addAlert('Something went wrong!');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const passwordConfirmation = signUp
    ? (
        <>
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            name="confirm-password"
            value={confirmPassword}
            onChange={(e) => {
              if (e.target.value === password) {
                setConfirmError(null);
              }
              setConfirmPassword(e.target.value);
            }}
            required
            type="password"
            autoComplete="new-password"
            disabled={isLoading}
          />
          <InputError error={confirmError} />
        </>
      )
    : null;

  const switchLink = signUp
    ? (
        <Link href="/sign-in">{"Aready have an account? Sign In"}</Link>
      )
    : resetPassword
      ? (
          <Link href="/sign-in">{"I remember now. Sign In"}</Link>
        )
      : (
          <Link href="/sign-up">{"Don't have an account? Sign Up"}</Link>
        );

  const allowSubmit = signUp
    ? (
        email && password && confirmPassword && !isLoading
      )
    : resetPassword
      ? (
          email && !isLoading
        )
      : (
          email && password && !isLoading
        );

  const popupContent = resetPassword
    ? (
      <div className="authentication-popup-content">
        <h3>{'We Reset Your Password'}</h3>
        <div>{`A reset password email has been sent to ${email}.`}</div>
        <div>{'Please click the link in that email to reset your password.'}</div>
      </div>
      )
    : (
      <div className="authentication-popup-content">
        <h3>{'Thanks for signing up!'}</h3>
        <div>{`A verification email has been sent to ${email}.`}</div>
        <div>{`Please click the link in that email then sign in.`}</div>
      </div>
      );

  const popup = showPopup
    ? (
        <Popup
          content={popupContent}
          onClose={() => {
            setShowPopup(false);
            setPassword("");
            setConfirmPassword("");
            switchPath('/');
          }}
        />
      )
    : null;

  const passwordLabel = signUp ? (
    <label htmlFor="password">Password</label>
  ) : (
    <div className="password-row">
      <label htmlFor="password">Password</label>
      <Link href="password-reset">{"Forgot password?"}</Link>
    </div>
  );

  const passwordInput = resetPassword ? null : (
    <>
      {passwordLabel}
      <input
        id="password"
        name="password"
        value={password}
        onChange={(e) => {
          if (e.target.value.length >= 6) {
            setPasswordError(null);
          }
          setPassword(e.target.value);
        }}
        required
        type="password"
        autoComplete={ signUp ? "new-password" : "current-password"}
        disabled={isLoading}
      />
      <InputError error={passwordError} />
    </>
  );

  const authenticationClasses = classNames('authentication', { loading: isLoading });

  const disclaimer = signUp ? (
    <div className="disclaimer">
      {"By signing up, you acknowledge that you have read and agree to our "}
      <Link external href="https://kendisc.com/terms-and-conditions">Terms and Conditions</Link>
      {" and our "}
      <Link external href="https://kendisc.com/privacy-policy">Privacy Policy</Link>
      {"."}
    </div>
  ) : null;

  return (
    <>
      <div className={authenticationClasses}>
        {popup}
        <div className="content">
          <h1>{ signUp ? "Sign Up" : resetPassword ? "Reset Password" : "Sign In" }</h1>
          <div className="form-container">
            <form onSubmit={handleSubmit} className="form" autoComplete="on">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmailError(null);
                  setEmail(e.target.value);
                }}
                required
                type="email"
                autoComplete="username"
                disabled={isLoading}
              />
              <InputError error={emailError} />
              {passwordInput}
              {passwordConfirmation}
              {disclaimer}
              <input
                disabled={!allowSubmit}
                className={allowSubmit ? "submit" : "submit disabled"}
                type="submit"
                value={signUp ? "Sign Up" : resetPassword ? "Reset Password" : "Sign In"}
              />
            </form>
          </div>
          {switchLink}
        </div>
      </div>
    </>

  );
};

export default Authentication;
