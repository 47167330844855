import React from "react";
import styled from "styled-components";
import Styles from "../helpers/styles";

interface ContainerProps {
  hidden?: boolean
}

const InputContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ hidden }) => hidden ? 'display: none;' : ''}
`;

const InputLabel = styled.label`
  color: var(--color-on-surface);
  margin-bottom: 8px;
  font-size: 14px;
`;

interface InputProps {
  readOnly?: boolean
}

const Input = styled.input<InputProps>`
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${Styles.colors.font};
  outline: none;
  font-size: 18px;
  border: 1px solid ${Styles.colors.border};
  padding: 5px;
  border-radius: 3px;
  background-color: var(--color-surface);

  &:focus-within {
    border-color: ${Styles.colors.emphasis};
  }
  margin-bottom: 15px;
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'cursor: text;' : ''}
`;

interface FormInputProps {
  label: string
  inputKey: string
  value: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  required?: boolean
  type?: string
  autoComplete?: string
  hidden?: boolean
}

const FormInput: React.FC<FormInputProps> = ({
  autoComplete,
  label,
  inputKey,
  value,
  onChange,
  disabled,
  required,
  type,
  hidden
}) => {
  return (
    <InputContainer hidden={hidden}>
      <InputLabel htmlFor={inputKey}>{label}</InputLabel>
      <Input
        autoComplete={autoComplete}
        id={inputKey}
        value={value}
        disabled={disabled}
        readOnly={disabled}
        onChange={onChange}
        required={required}
        type={type}
      />
    </InputContainer>
  );
};

export default FormInput;
