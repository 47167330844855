import React from "react";
import styled, { css } from "styled-components";
import type LeagueModel from "../../../../../api/LeagueModel";
import type CompetitorModel from "../../../../../api/CompetitorModel";
import type DarkHorsePickModel from "../../../../../api/DarkHorsePickModel";
import Styles from "../../../../../helpers/styles";
import { useCompetitorStore } from "../../../../../stores";
import Checkbox from "../../../../../components/Checkbox";
import Icon, { ICON_COLORS, ICON_TYPES } from "../../../../../components/Icon";

const Container = styled.div`
  width: 100%;
`;

const SectionHeading = styled.h1`
  color: ${Styles.colors.font};
  margin: 0px;
`;

const SharedCellStyles = css`
  padding: 10px;
  border-top: 2px solid #b7c1ff;
  border-bottom: 2px solid #b7c1ff;
  background-color: #1d2a70;
  &:last-child {
    border-right: 2px solid #b7c1ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:first-child {
    border-left: 2px solid #b7c1ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const NameCell = styled.td`
  color: ${Styles.colors.font};
  ${SharedCellStyles}
`;

const PriorityCell = styled.td`
  color: ${Styles.colors.font};
  ${SharedCellStyles}
`;

const PriortyCellContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

const CheckCell = styled.td`
  width: 100px;
  @media (max-width: 768px) {
    width: 40px;
  }
  ${SharedCellStyles}
`;

const HeaderCell = styled.th`
  color: ${Styles.colors.font};
  text-align: left;
  padding: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
`;

interface RowProps {
  checked?: boolean
}

const CheckedRowStyle = css`
  > td {
    border-color: #0c9c29;

    &:first-child {
      border-left-color: #0c9c29;
    }

    &:last-child {
      border-right-color: #0c9c29;
    }
  }
`;

const CompetitorRow = styled.tr<RowProps>`
  cursor: pointer;
  &:hover > td {
    background-color: #223182;
  }
  ${({ checked }) => checked ? CheckedRowStyle : ''}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-top: -15px;
`;

const SaveRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  color: ${Styles.colors.font};
  margin-bottom: 5px;

  > .icon {
    display: flex;
    fill: ${Styles.colors.green};
    stroke: ${Styles.colors.green};

    &.saving {
      fill: ${Styles.colors.font};
      stroke: ${Styles.colors.font};
    }
  }
`;

const HiddenMobile = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

interface DarkHorseProps {
  league: LeagueModel
  eventId: string
}

const DarkHorse: React.FC<DarkHorseProps> = ({ league, eventId }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [darkHorsePicks, setDarkHorsePicks] = React.useState<DarkHorsePickModel[]>([]);
  const addCompetitors = useCompetitorStore(store => store.addCompetitors);
  const getCompetitor = useCompetitorStore(store => store.getCompetitor);
  const [darkHorses, setDarkHorses] = React.useState<CompetitorModel[]>([]);
  const [requiredPicks, setRequiredPicks] = React.useState(0);

  React.useEffect(() => {
    const performSearch = async (): Promise<any> => {
      const [competitors, picks, requiredPickCount] = await Promise.all([
        league.getDarkHorses(eventId),
        league.getDarkHorsePicks(eventId),
        league.getDarkHorseRequiredPicks(eventId)
      ]);
      setRequiredPicks(requiredPickCount);
      const readCompetitors = Object.values(competitors);
      addCompetitors(competitors);
      setDarkHorses(readCompetitors);
      setDarkHorsePicks(Object.values(picks));
    };

    performSearch();
  }, []);

  const unpickedCompetitors = React.useMemo(() => {
    return darkHorses.filter((darkHorse) => {
      return !darkHorsePicks.some((darkHorsePick) => {
        return darkHorsePick.competitorId === darkHorse.id;
      });
    });
  }, [darkHorses, darkHorsePicks]);

  const unpickedRows = React.useMemo(() => {
    const sorted = Array.from(unpickedCompetitors);
    sorted.sort((a, b) => b.rating - a.rating);
    return sorted.map((competitor) => {
      const onClick = async (): Promise<any> => {
        try {
          if (isSaving) {
            return;
          }
          setIsSaving(true);
          const darkHorsePick = await league.createDarkHorse({
            eventId,
            competitorId: competitor.id,
            priority: darkHorsePicks.length + 1
          });
          setDarkHorsePicks(curr => [...curr, darkHorsePick]);
        } finally {
          setIsSaving(false);
        }
      };

      return (
        <CompetitorRow onClick={onClick} checked={false} key={competitor.id}>
          <CheckCell>
            <Checkbox
              id={`unpicked-${competitor.id}`}
              checked={false}
            />
          </CheckCell>
          <NameCell>{competitor.name}</NameCell>
          <NameCell>{competitor.rating}</NameCell>
        </CompetitorRow>
      );
    });
  }, [unpickedCompetitors, league]);

  const pickedRows = React.useMemo(() => {
    const sortedPicks = Array.from(darkHorsePicks);
    sortedPicks.sort((a, b) => a.priority - b.priority);
    return sortedPicks.map((darkHorsePick, i) => {
      const competitor = getCompetitor(darkHorsePick.competitorId);

      const onClick = async (): Promise<any> => {
        try {
          setIsSaving(true);
          const newPicks = await league.deleteDarkHorsePick({ eventId, darkHorsePickId: darkHorsePick.id });
          setDarkHorsePicks(Object.values(newPicks));
        } finally {
          setIsSaving(false);
        }
      };

      const canMoveUp = i > 0;
      const canMoveDown = i + 1 < sortedPicks.length;
      const onMoveUp = async (event: React.MouseEvent): Promise<any> => {
        event.stopPropagation();
        if (canMoveUp) {
          try {
            setIsSaving(true);
            const newPicks = await league.moveDarkHorsePickUp({ eventId, darkHorsePickId: darkHorsePick.id });
            setDarkHorsePicks(Object.values(newPicks));
          } finally {
            setIsSaving(false);
          }
        }
      };

      const onMoveDown = async (event: React.MouseEvent): Promise<any> => {
        event.stopPropagation();
        if (canMoveDown) {
          try {
            setIsSaving(true);
            const newPicks = await league.moveDarkHorsePickDown({ eventId, darkHorsePickId: darkHorsePick.id });
            setDarkHorsePicks(Object.values(newPicks));
          } finally {
            setIsSaving(false);
          }
        }
      };

      return (
        <CompetitorRow onClick={onClick} checked key={competitor.id}>
          <CheckCell>
            <Checkbox
              id={`dark-horse-pick-${darkHorsePick.id}`}
              checked
            />
          </CheckCell>
          <PriorityCell>
            <PriortyCellContent>
              {darkHorsePick.priority}
              {
                <Icon
                  size={20}
                  type={ICON_TYPES.CIRCLE_UP}
                  onClick={onMoveUp}
                  color={ canMoveUp ? ICON_COLORS.WHITE : ICON_COLORS.DISABLED }
                  defaultCursor={ !canMoveUp }
                />
              }
              {
                <Icon
                  size={20}
                  type={ICON_TYPES.CIRCLE_DOWN}
                  onClick={onMoveDown}
                  color={ canMoveDown ? ICON_COLORS.WHITE : ICON_COLORS.DISABLED }
                  defaultCursor={ !canMoveDown }
                />
              }
            </PriortyCellContent>
          </PriorityCell>
          <NameCell>{competitor.name}</NameCell>
          <NameCell>{competitor.rating}</NameCell>
        </CompetitorRow>
      );
    });
  }, [darkHorsePicks, getCompetitor]);

  const saveRow = (
    <SaveRow>
      <Icon className={isSaving ? 'saving' : ''} type={ICON_TYPES.CIRCLE_CHECK} spinning={isSaving} size={15} />
      {isSaving ? "Saving picks..." : "Your picks were auto-saved"}
    </SaveRow>
  );

  return (
    <Container>
      {saveRow}
      <TableContainer>
        <SectionHeading>Dark Horse Selections</SectionHeading>
        <Table>
          <thead>
            <tr>
              <HeaderCell>
                {`${darkHorsePicks.length} / ${requiredPicks}`}
                <HiddenMobile>{' Picks'}</HiddenMobile>
              </HeaderCell>
              {darkHorsePicks.length ? <HeaderCell>{'Priority'}</HeaderCell> : null}
              {darkHorsePicks.length ? <HeaderCell>{'Name'}</HeaderCell> : null}
              {darkHorsePicks.length ? <HeaderCell>{'Rating'}</HeaderCell> : null}
            </tr>
          </thead>
          <tbody>
            {pickedRows}
          </tbody>
        </Table>
      </TableContainer>
      <TableContainer>
        <SectionHeading>Available Players</SectionHeading>
        <Table>
        <thead>
            <tr>
              <HeaderCell>{}</HeaderCell>
              <HeaderCell>{'Name'}</HeaderCell>
              <HeaderCell>{'Rating'}</HeaderCell>
            </tr>
          </thead>
          <tbody>
            {unpickedRows}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DarkHorse;
