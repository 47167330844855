import { create } from 'zustand';
import type EventPickModel from '../api/EventPickModel';

interface EventPickState {
  // TODO: Allow for this to be properly stored in state
  eventPicks: Record<string, EventPickModel>
  setEventPicks: (eventPickSet: Record<string, EventPickModel>) => void
  removeEventPick: (eventPickId: string) => void
  addEventPick: (eventPick: EventPickModel) => void
}

const useEventPickStore = create<EventPickState>((set, get) => ({
  eventPicks: {},
  setEventPicks: (eventPicks) => set(() => ({ eventPicks })),
  removeEventPick: (eventPickId) => set(({ eventPicks }) => {
    delete eventPicks[eventPickId];

    return eventPicks;
  }),
  addEventPick: (eventPick) => set(({ eventPicks }) => {
    eventPicks[eventPick.id] = eventPick;

    return eventPicks;
  })
}));

export default useEventPickStore;
