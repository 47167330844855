import { type FirebaseOptions } from "firebase/app";

// TODO: Setup test environment
const FIREBASE_CONFIGS: Record<string, FirebaseOptions> = {
  development: {
    apiKey: "AIzaSyAz3AuM-X39Gs0n3gtZSHPujWPJ2qfz0Oo",
    authDomain: "fantasy-disc-golf-4d640.firebaseapp.com",
    projectId: "fantasy-disc-golf-4d640",
    storageBucket: "fantasy-disc-golf-4d640.appspot.com",
    messagingSenderId: "874270226364",
    appId: "1:874270226364:web:acb70c6d018f65ae991db9",
    measurementId: "G-RC0434N4MY"
  },
  test: {
    apiKey: "AIzaSyAz3AuM-X39Gs0n3gtZSHPujWPJ2qfz0Oo",
    authDomain: "fantasy-disc-golf-4d640.firebaseapp.com",
    projectId: "fantasy-disc-golf-4d640",
    storageBucket: "fantasy-disc-golf-4d640.appspot.com",
    messagingSenderId: "874270226364",
    appId: "1:874270226364:web:acb70c6d018f65ae991db9",
    measurementId: "G-RC0434N4MY"
  },
  production: {
    apiKey: "AIzaSyAz3AuM-X39Gs0n3gtZSHPujWPJ2qfz0Oo",
    authDomain: "fantasy-disc-golf-4d640.firebaseapp.com",
    projectId: "fantasy-disc-golf-4d640",
    storageBucket: "fantasy-disc-golf-4d640.appspot.com",
    messagingSenderId: "874270226364",
    appId: "1:874270226364:web:acb70c6d018f65ae991db9",
    measurementId: "G-RC0434N4MY"
  }
};

export default FIREBASE_CONFIGS;
