import React from "react";
import ManufacturerModel from "../../../api/ManufacturerModel";
import GenericTable from "../fantasy/league/GenericTable";
import Popup from "../../../components/Popup";
import Form from "../../../components/Form";
import FormInput from "../../../components/FormInput";
import Admin from "../../../api/Admin";
import FormSubmit from "../../../components/FormSubmit";

const AdminManufacturers: React.FC = () => {
  const [manufacturers, setManufacturers] = React.useState<Record<string, ManufacturerModel>>({});

  const [isSaving, setIsSaving] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    const loadManufacturers = async (): Promise<any> => {
      const readManufacturers = await ManufacturerModel.fetchAll() as Record<string, ManufacturerModel>;
      setManufacturers(readManufacturers);
    };

    loadManufacturers();
  }, []);

  const rows = React.useMemo(() => {
    return Object.values(manufacturers).map((manufacturer) => {
      return {
        cells: [
          manufacturer.id,
          manufacturer.name
        ],
        key: manufacturer.id
      };
    });
  }, [manufacturers]);

  const headers = [
    "ID",
    "Name"
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();

    setIsSaving(true);
    try {
      const newManufacturer = await Admin.createManufacturer({ name }) as ManufacturerModel;
      setManufacturers((currentManufacturers) => ({
        ...currentManufacturers,
        [newManufacturer.id]: newManufacturer
      }));
    } finally {
      setIsSaving(false);
    }
  };

  const addPopupContent = (
    <div className="add-popup-content">
      <h2>Add Event</h2>
      <Form onSubmit={handleSubmit}>
        <FormInput
          label="Name"
          inputKey="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          disabled={isSaving}
          required
        />
        <FormSubmit disabled={isSaving} />
      </Form>
    </div>
  );

  const addPopup = showAdd ? (
    <Popup
      content={addPopupContent}
      onClose={() => setShowAdd(false)}
    />
  ) : null;

  return (
    <div>
      {addPopup}
      <button onClick={() => setShowAdd(true)}>{'Add Manufacturer'}</button>
      <GenericTable
        headers={headers}
        rows={rows}
      />
    </div>
  );
};

export default AdminManufacturers;
