import React from "react";
import styled, { keyframes } from "styled-components";
import Icon, { ICON_COLORS } from "./Icon";

interface Props {
  icon?: string
  message: string
  color?: string
}

const AlertFrames = keyframes`
  0% {
    transform: translateY(400px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const AlertContainer = styled.div`
  max-width: 90vw;
  width: 500px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #fff;
  transform: translateY(0px);
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: ${AlertFrames} 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 20px 35px 20px 25px;
`;

const ProgressFrames = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`;

const ProgressDiv = styled.div`
  height: 3px;
  background-color: var(--color-primary);

  animation: ${ProgressFrames} 5s linear forwards;
`;

const Alert: React.FC<Props> = ({ message, color = 'red', icon }) => {
  const iconComponent = icon ? (
    <Icon color={ICON_COLORS.EMPHASIS} size={32} type={icon} />
  ) : null;
  return (
    <AlertContainer>
      <AlertContent>
        {iconComponent}
        {message}
      </AlertContent>

      <ProgressDiv />
    </AlertContainer>
  );
};

export default Alert;
