import singletons from './singletons';

const BASE_URL = process.env.ENVIRONMENT === 'development'
  ? 'http://localhost:5001'
  : 'https://api.kendisc.com';

export interface RequestOptions {
  method?: string
  body?: object
}

const Request = async (extension: string, { method = 'GET', body }: RequestOptions = {}): Promise<any> => {
  const user = singletons.auth?.currentUser;
  const forceRefreshToken = singletons.forceRefreshToken;
  const token = user && (await user.getIdToken(forceRefreshToken));
  singletons.forceRefreshToken = false;

  let response: Response | undefined;
  if (token) {
    const cloakedUserId = singletons.cloakedUserId;

    response = await fetch(`${BASE_URL}${extension}`, {
      method,
      mode: 'cors',
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Cloaked-User-Id': cloakedUserId ?? ""
      })
    });
  } else {
    response = await fetch(`${BASE_URL}${extension}`, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  if (!response.ok) {
    const json = await response.json();
    if (typeof json.error === 'string') {
      throw new Error(json.error as string);
    }
    throw new Error('Something went wrong');
  }

  return response.json();
};

export default Request;
