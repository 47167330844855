import React from "react";

import type LeagueModel from "../../../../api/LeagueModel";
import styled from "styled-components";
import switchPath from "../../../../helpers/switchPath";
import GenericTable from "./GenericTable";
import { useEventStore } from "../../../../stores";
import type EventModel from "../../../../api/EventModel";

const TableContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;

const Name = styled.span`
  font-weight: 700;
`;

const PresentedBy = styled.span`
  font-size: 13px;
`;

const Header = styled.h1`
  &:first-child {
    margin-top: 0px;
  }
  color: var(--color-white);
`;

interface EventsProps {
  league: LeagueModel
}

interface EventsTableProps {
  events: EventModel[]
  league: LeagueModel
}

const EventsTable: React.FC<EventsTableProps> = ({ events, league }) => {
  const [sortIndex, setSortIndex] = React.useState<number>(2);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');

  const sort = (newSortIndex: number): void => {
    if (sortIndex === newSortIndex) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortIndex(newSortIndex);
      setSortDirection('desc');
    }
  };

  const sortedEvents = React.useMemo(() => {
    const sortedEvents = Object.values(events);
    sortedEvents.sort((a, b) => {
      if (sortIndex === 0) {
        const difference = a.name.localeCompare(b.name);
        return sortDirection === 'asc' ? difference * -1 : difference;
      } else if (sortIndex === 1) {
        const difference = a.location.localeCompare(b.location);
        return sortDirection === 'asc' ? difference * -1 : difference;
      } else if (sortIndex === 2 || sortIndex === 3) {
        const aEndDate = new Date(a.endDate);
        const bEndDate = new Date(b.endDate);
        const difference = aEndDate < bEndDate ? -1 : 1;
        return sortDirection === 'desc' ? difference : difference * -1;
      }

      return 0;
    });

    return sortedEvents;
  }, [events, sortIndex, sortDirection]);

  const rows = React.useMemo(() => {
    return sortedEvents.map((e) => {
      const onClick = (): void => {
        switchPath(`/fantasy/leagues/${league.id}/events/${e.id}`);
      };
      const nameCell = (
        <NameContainer>
          <Name>{e.name}</Name>
          <PresentedBy>{e.presentedBy}</PresentedBy>
        </NameContainer>
      );
      const cells = [
        nameCell,
        e.location,
        e.formattedStartDate,
        e.formattedEndDate
      ];

      return {
        onClick,
        cells,
        key: e.id
      };
    });
  }, [sortedEvents]);

  const headers = [
    "Name",
    "Location",
    "Start",
    "End"
  ];

  return (
    <TableContainer>
      <GenericTable
        rows={rows}
        headers={headers}
        mobileHiddenIndices={[1, 3]}
        sortableIndices={[0, 1, 2, 3]}
        sortedIndex={sortIndex}
        sortDirection={sortDirection}
        sort={sort}
      />
    </TableContainer>
  );
};

const Events: React.FC<EventsProps> = ({ league }) => {
  const events = useEventStore((state) => state.events);
  const addEvents = useEventStore((state) => state.addEvents);

  React.useEffect(() => {
    const loadEvents = async (): Promise<any> => {
      const readEvents = await league.getEvents();
      addEvents(readEvents);
    };

    loadEvents();
  }, [league.id]);

  const upcomingEvents = Object.values(events).filter((event) => {
    return event.isCurrentEvent || event.isUpcomingEvent;
  });

  const pastEvents = Object.values(events).filter((event) => {
    return event.isPastEvent;
  });

  return (
    <TableContainer>
      <Header>{"Current & Upcoming Events"}</Header>
      <EventsTable
        league={league}
        events={upcomingEvents}
      />
      <Header>{"Past Events"}</Header>
      <EventsTable
        league={league}
        events={pastEvents}
      />
    </TableContainer>
  );
};

export default Events;
