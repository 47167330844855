import Model from "./Model";
import Request, { type RequestOptions } from "./Request";

const RequestAndParse = async (extension: string, requestOptions?: RequestOptions, useCache?: boolean): Promise<any> => {
  const data = await Request(extension, requestOptions);

  return Model.parseData(data, useCache);
};

export default RequestAndParse;
