import React from "react";
import classNames from "../helpers/classNames";

interface LogoProps {
  className?: string
  size: number
  spinning?: boolean
  color?: string
}

const Logo: React.FC<LogoProps> = ({ className, size, spinning, color }) => {
  const colorClass = color ?? 'white';
  const logoClasses = classNames('kendisc-logo', className, colorClass, { spinning });
  return (
    <svg className={logoClasses} viewBox="29.67 91.68 385.06 312.53" xmlns="http://www.w3.org/2000/svg" height={size}>
      <ellipse className="disc" style={{ fill: 'none', strokeWidth: '10px' }} cx="258.464" cy="247.94" rx="151.264" ry="151.264"/>
      <path className="k" d="M -277.391 -99.025 L -277.391 -398.667 L -237.738 -398.667 L -237.738 -250.073 L -88.939 -398.667 L -35.183 -398.667 L -160.886 -277.257 L -29.665 -99.025 L -81.99 -99.025 L -188.684 -250.686 L -237.738 -202.857 L -237.738 -99.025 Z" transform="matrix(-1, 0, 0, -1, 0, 0)" style={{ whiteSpace: 'pre' }}/>
    </svg>
  );
};

export default Logo;
