import React from 'react';
import WeeklyPick, { type WeeklyPickUserPick, type WeeklyPickResult } from '../../../../api/WeeklyPick';
import GenericTable from '../league/GenericTable';
import switchPath from '../../../../helpers/switchPath';
import styled from 'styled-components';

interface EventResultsProps {
  eventId: string
}

const UserPickSection = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  overflow-x: scroll;
  align-items: center;
  background-color: var(--color-surface-two);
  padding: 5px 10px;
`;

const UserPick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 7px;
  > * {
    white-space: nowrap;
    color: var(--color-on-surface);
  }
`;

const EventResults: React.FC<EventResultsProps> = ({ eventId }) => {
  const [results, setResults] = React.useState<WeeklyPickResult[]>([]);
  const [userPicks, setUserPicks] = React.useState<Record<string, WeeklyPickUserPick[]>>({});
  const [expandedUsers, setExpandedUsers] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    const loadData = async (): Promise<any> => {
      try {
        const data = await WeeklyPick.fetchInitialData(eventId);
        setResults(data.results ?? []);
      } catch (error) {
        switchPath('/');
      }
    };

    loadData();
  }, [eventId]);

  const headers = [
    'Place',
    'Name',
    'Points'
  ];

  const rows = results.map((result, i) => {
    const expanded = expandedUsers[result.userId];
    const cells = [String(i + 1), result.username, Number(result.points).toFixed(2)];
    const onClick = async (): Promise<any> => {
      if (!userPicks[result.userId]) {
        const picks = await WeeklyPick.getUserPicks(eventId, result.userId);
        picks.sort((a, b) => {
          if (a.division === 'FPO' && b.division === 'MPO') {
            return -1;
          } else if (a.division === 'MPO' && b.division === 'FPO') {
            return 1;
          }

          return (b.points - a.points);
        });
        setUserPicks((currentUserPicks) => {
          return {
            ...currentUserPicks,
            [result.userId]: picks
          };
        });
      }

      setExpandedUsers((currentExpandedUsers) => ({
        ...currentExpandedUsers,
        [result.userId]: !expanded
      }));
    };

    let expandedSection: React.ReactNode | undefined;
    if (expanded) {
      const picks = userPicks[result.userId].map((pick) => {
        return (
          <UserPick key={pick.competitor_id}>
            <span>{pick.name}</span>
            <span>{pick.division}</span>
            <span>{Number(pick.points).toFixed(2)}</span>
          </UserPick>
        );
      });
      expandedSection = (
        <UserPickSection>
          {picks}
        </UserPickSection>
      );
    }

    return {
      cells,
      key: result.username,
      onClick,
      expanded,
      expandedSection
    };
  });

  return (
    <>
      <GenericTable
        rows={rows}
        headers={headers}
      />
    </>
  );
};

export default EventResults;
