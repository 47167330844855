import React from "react";
import styled, { css } from "styled-components";
import Styles from "../helpers/styles";

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

interface SubmitProps {
  disabled: boolean
  hidden: boolean
}

const EnabledStyles = css`
  background-color: ${Styles.colors.emphasis};
  cursor: pointer;
  color: ${Styles.colors.white};
  &:hover {
    background-color: #1d2a70;
  }
`;

const Submit = styled.input<SubmitProps>`
  background-color: #464c69;
  font-size: 24px;
  padding: 10px 50px;
  color: #d4cecd;
  border-radius: 4px;
  border: none;
  ${({ disabled }) => disabled ? '' : EnabledStyles}
  ${({ hidden }) => hidden ? 'display: none;' : ''}
`;

interface FormSubmitProps {
  hidden?: boolean
  label?: string
  disabled: boolean
}

const FormSubmit: React.FC<FormSubmitProps> = ({ hidden, label, disabled }) => {
  const value = label ?? "Save";

  return (
    <SubmitContainer>
      <Submit
        hidden={Boolean(hidden)}
        type="submit"
        value={value}
        disabled={disabled}
      />
    </SubmitContainer>
  );
};

export default FormSubmit;
