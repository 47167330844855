import Model from "./Model";

class ManufacturerModel extends Model {
  static apiPath = '/manufacturers';
  public name: string;
}

Model.registerModel('manufacturers', ManufacturerModel);

export default ManufacturerModel;
