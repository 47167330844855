import React from 'react';

import LeagueModel from '../../../api/LeagueModel';

import Link from '../../../components/Link';
import Icon, { ICON_TYPES } from '../../../components/Icon';
import Logo from '../../../components/logo';
import { useLeagueStore } from '../../../stores';
import styled, { css } from 'styled-components';
import Styles from '../../../helpers/styles';

interface SidebarProps {
  expanded: boolean
  setExpanded: () => void
  signedIn?: boolean
  path: string
}

interface ExpandableProps {
  expanded: boolean
}

const ExpandedStyles = css`
  transform: translateX(0);
  box-shadow: 8px 0 7px 0px #545454;
`;

const UnexpandedStyles = css`
  transform: translateX(-256px);
`;

const FantasySidebarContainer = styled.div<ExpandableProps>`
  background-color: var(--color-surface);
  min-width: 256px;
  width: 256px;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 20px;
  z-index: 1000;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    position: fixed;
    z-index: 103;
    transition: 0.4s all ease-in-out;

    ${({ expanded }) => expanded ? ExpandedStyles : UnexpandedStyles}
  }
`;

interface SelectableProps {
  selected: boolean
}

const SelectedLinkCSS = css`
  color: var(--color-on-primary) !important;
  background-color: var(--color-primary) !important;
`;

const MainLink = styled(Link)<SelectableProps>`
  color: var(--color-on-surface) !important;
  font-size: 18px;
  text-decoration: none !important;
  &:hover {
    background-color: var(--color-on-surface-hover);
  }
  padding: 5px 10px;
  border-radius: 16px;

  &:not(:first-child) {
    margin-top: 10px;
  }
  cursor: pointer;

  ${({ selected }) => selected ? SelectedLinkCSS : ''}
`;

const LeagueLink = styled(MainLink)`
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const IconSelectedStyles = css`
  fill: var(--color-on-primary);
  stroke: var(--color-on-primary);
`;

const IconLeagueLink = styled(LeagueLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

  > .icon {
    display: flex;
    fill: var(--color-on-surface);
    stroke: var(--color-on-surface);

    ${({ selected }) => selected ? IconSelectedStyles : ''}
    opacity: 1;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  width: 100%;

  > span {
    color: var(--color-on-surface) !important;
  }
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  justify-content: center;
  width: 100%;
`;

const LogoGroup = styled(Link)`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 7px;
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
  text-decoration: none !important;
`;

const LogoText = styled.h1`
  color: var(--color-white);
  margin: 0px;
  text-decoration: none !important;
`;

const Divider = styled.hr`
  width: 100%;
  border-color: var(--color-on-surface);
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom-width: 0px;
`;

const ExpandedOverlayStyles = css`
  @media (max-width: ${Styles.mobileBreakpoint}) {
    width: 100vw;
    height: 100vh;
    background-color: #545454;
    z-index: 102;
    position: fixed;
    opacity: 0.5;
  }
`;

const Overlay = styled.div<ExpandableProps>`
  cursor: pointer;
  opacity: 0;
  transition: 0.4s opacity ease-in-out;
  ${({ expanded }) => expanded ? ExpandedOverlayStyles : ''}
`;

const StyledIcon = styled(Icon)`
  &:hover {
    fill: #5865F2;
  }
`;

const InstaIcon = styled(Icon)`
  &:hover {
    fill: #D300C5;
  }
`;

const FantasySidebar: React.FC<SidebarProps> = ({ expanded, setExpanded, signedIn, path }) => {
  const leagues = useLeagueStore((state) => state.leagues);
  const addLeagues = useLeagueStore((state) => state.addLeagues);

  const splitPath = path.split('/');

  React.useEffect(() => {
    const getLeagues = async (): Promise<any> => {
      const fetchedLeagues = await LeagueModel.fetchAll();
      addLeagues(fetchedLeagues as Record<string, LeagueModel>);
    };
    if (signedIn) {
      getLeagues();
    }
  }, [signedIn]);

  const leagueListLinks = Object.values(leagues).map(league => {
    const selected = splitPath[2] === 'leagues' && splitPath[3] === String(league.id);
    return (
      <LeagueLink
        key={league.id}
        href={`/fantasy/leagues/${league.id}`}
        selected={selected}
      >
        {league.name}
      </LeagueLink>
    );
  });

  const parThreeSelected = splitPath[1] === 'par-three-picks';
  const leaguesSelected = splitPath[1] === 'fantasy' && splitPath.length === 2;
  const addSelected = splitPath[2] === 'leagues' && (
    splitPath[3] === 'join' ||
    splitPath[3] === 'create'
  );
  const discleSelected = splitPath[1] === 'mcbirdle';
  const signInSelected = ['sign-in', 'sign-up', 'password-reset'].includes(splitPath[1]);

  return (
    <>
      <Overlay expanded={expanded} onClick={setExpanded} />
      <FantasySidebarContainer expanded={expanded}>
        <TopSection>
          <LogoGroup href="/fantasy">
            <Logo color="emphasis" size={30} />
            <LogoText>{ "Kendisc" }</LogoText>
          </LogoGroup>
          <Divider />
          <LinkContainer>
            <MainLink selected={discleSelected} href="/mcbirdle">
              {"McBirdle"}
            </MainLink>
            <MainLink selected={parThreeSelected} href={ signedIn ? "/par-three-picks" : "/sign-in" }>
              {"Par 3 Pick 'em"}
            </MainLink>
            <MainLink selected={leaguesSelected} href={ signedIn ? "/fantasy" : "/sign-in" }>Leagues</MainLink>
            {leagueListLinks}
            <IconLeagueLink
              selected={addSelected}
              href={signedIn ? "/fantasy/leagues/join" : "/sign-in"}
            >
              <Icon type={ICON_TYPES.CIRCLE_PLUS} size={20}/>
              <span>{'Join/Create a League'}</span>
            </IconLeagueLink>
            {signedIn ? null : <MainLink selected={signInSelected} href="/sign-in">
              {"Sign In/Sign Up"}
            </MainLink>}
          </LinkContainer>
        </TopSection>
        <BottomSection>
          <a href="https://discord.gg/karGPeMeCh" target="_blank" rel="noreferrer">
            <StyledIcon type={ICON_TYPES.DISCORD} size={40} />
          </a>
          <a href="https://www.instagram.com/officialkendisc/" target="_blank" rel="noreferrer">
            <InstaIcon type={ICON_TYPES.INSTAGRAM} size={40} />
          </a>
        </BottomSection>
      </FantasySidebarContainer>
    </>
  );
};

export default FantasySidebar;
