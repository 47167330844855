import { create } from 'zustand';
import type LeagueMemberModel from '../api/LeagueMemberModel';

interface LeagueMemberState {
  // put league members under their league id
  leagueMembers: Record<string, Record<string, LeagueMemberModel>>
  addLeagueMember: (leagueId: string, leagueMember: LeagueMemberModel) => void
  addLeagueMembers: (leagueId, leagueMemberSet: Record<string, LeagueMemberModel>) => void
  getLeagueMembersByLeague: (leagueId: string) => LeagueMemberModel[]
  getLeagueMemberByUserId: (leagueId: string, userId?: string) => LeagueMemberModel | undefined
}

const useLeagueMemberStore = create<LeagueMemberState>((set, get) => ({
  leagueMembers: {},
  addLeagueMember: (leagueId, leagueMember) => set(({ leagueMembers }) => {
    if (!leagueMembers[leagueId]) {
      leagueMembers[leagueId] = {};
    }
    leagueMembers[leagueId][leagueMember.id] = leagueMember;
    return leagueMembers;
  }),
  addLeagueMembers: (leagueId, leagueMemberSet) => set(({ leagueMembers }) => {
    leagueMembers[leagueId] = { ...leagueMembers[leagueId], ...leagueMemberSet };

    return leagueMembers;
  }),
  getLeagueMembersByLeague: (leagueId: string) => (
    Object.values(get().leagueMembers[leagueId] ?? {})
  ),
  getLeagueMemberByUserId: (leagueId: string, userId?: string) => {
    if (!userId) {
      return;
    }
    return Object.values(get().leagueMembers[leagueId] ?? {}).find(leagueMember => leagueMember.userId === userId);
  }
}));

export default useLeagueMemberStore;
