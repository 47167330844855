import React from "react";
import styled, { css } from "styled-components";

interface TabProps {
  selected?: boolean
}

const selectedCss = css`
  background-color: #263894;
  color: var(--color-on-primary);
  border-bottom: 2px solid var(--color-primary);
  margin-bottom: -1px;
`;

const unselectedCss = css`
  background-color: none;

  &:hover {
    background-color: #1d2a70;
  }

  &:active {
    background-color: #223182;
  }
`;

const Tab = styled.a<TabProps>`
  padding: 7px 10px;
  border-radius: 4px 4px 0px 0px;
  color: var(--color-on-surface);
  font-size: 1.1em;
  cursor: pointer;
  ${({ selected }) => selected ? selectedCss : unselectedCss}
`;

const TabList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-on-surface);
`;

interface TabObject {
  id: string
  name: string
}

interface TabBarProps {
  tabs: Record<string, TabObject>
  tab: string
  setTab: (tab: string) => void
}

const TabBar: React.FC<TabBarProps> = ({ tabs, tab, setTab }) => {
  const tabComponents = Object.keys(tabs).map((tabKey) => {
    return (
      <Tab
        key={tabKey}
        onClick={() => setTab(tabKey)}
        selected={tab === tabKey}
      >
        {tabs[tabKey].name}
      </Tab>
    );
  });

  return (
    <TabList>
      {tabComponents}
    </TabList>
  );
};

export default TabBar;
