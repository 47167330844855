import React from "react";
import Styles from "../../helpers/styles";
import styled, { css } from "styled-components";
import LeagueModel from "../../api/LeagueModel";
import switchPath from "../../helpers/switchPath";
import Link from "../../components/Link";
import Icon, { ICON_COLORS, ICON_TYPES } from "../../components/Icon";
import { useLeagueStore } from "../../stores";

const BackLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 7px;
  cursor: pointer;
  margin-bottom: 5px;
  align-self: flex-start;
  margin: 10px;

  > .icon {
    transform: rotate(180deg);
  }
`;

const Header = styled.h1`
  color: ${Styles.colors.white};
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ContainerProps {
  hasError: boolean
}

const NameContainer = styled.div<ContainerProps>`
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Styles.colors.border};
  padding: 5px;
  border-radius: 3px;
  background-color: ${Styles.colors.page};

  &:focus-within {
    ${({ hasError }) => hasError ? '' : `border-color: ${Styles.colors.emphasis};`}
  }

  ${({ hasError }) => hasError ? 'border-color: red;' : ''}
  margin-bottom: 15px;
`;

const NameLabel = styled.label`
  color: ${Styles.colors.font};
  font-size: 12px;
  width: 100%;
  padding-bottom: 8px;
`;

const NameInput = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${Styles.colors.font};
  outline: none;
  font-size: 18px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid ${Styles.colors.border};
`;

const StyledLink = styled(Link)`
  margin-top: 10px;
`;

interface SubmitProps {
  disabled: boolean
}

const EnabledStyles = css`
  background-color: ${Styles.colors.emphasis};
  cursor: pointer;
  &:hover {
    background-color: #1d2a70;
  }
`;

const Submit = styled.input<SubmitProps>`
  background-color: ${Styles.colors.page};
  font-size: 24px;
  padding: 10px 50px;
  color: ${Styles.colors.white};
  border-radius: 9999px;
  border: none;
  width: 90%;
  max-width: 500px;
  ${({ disabled }) => disabled ? '' : EnabledStyles}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface JoinProps {
  leagueId: string
}

const LeagueJoin: React.FC<JoinProps> = ({ leagueId }) => {
  const params = new URLSearchParams(window.location.search);
  const passwordParam = params.get('pwd');
  const [leaguePassword, setLeaguePassword] = React.useState(passwordParam ?? "");
  const [teamName, setTeamName] = React.useState("");
  const [league, setLeague] = React.useState<LeagueModel | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const addLeague = useLeagueStore((state) => state.addLeague);

  React.useEffect(() => {
    const load = async (): Promise<any> => {
      if (!leagueId) {
        return;
      }

      const readLeague = await LeagueModel.read(leagueId);
      setLeague(readLeague as LeagueModel);
    };

    load();
  }, [leagueId]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();

    if (!canSubmit) {
      return;
    }

    try {
      const newLeague = await LeagueModel.join({ leagueId, password: leaguePassword, teamName });
      addLeague(newLeague);
      switchPath(`/fantasy/leagues/${leagueId}`);
    } catch (error) {
      setError('Incorrect Password');
      console.log(error);
    }
  };

  const canSubmit = React.useMemo(() => {
    return Boolean(leaguePassword.length) && Boolean(teamName.length);
  }, [leaguePassword, teamName]);

  if (!league) {
    return null;
  }

  const backToSearch = (
    <BackLink href="/fantasy/leagues/join">
      <Icon type={ICON_TYPES.CIRCLE_RIGHT} size={20} color={ICON_COLORS.EMPHASIS} />
      <span>{'Back to League Search'}</span>
    </BackLink>
  );

  return (
    <Page>
      {backToSearch}
      <Header>{`Join ${league.name}`}</Header>
      <Form onSubmit={handleSubmit}>
        <NameContainer hasError={Boolean(error)}>
          <NameLabel htmlFor="league-team-name">{"Team Name"}</NameLabel>
          <NameInput
            id="league-team-name"
            value={teamName}
            onChange={(e) => {
              if (e.target.value.length > 256) {
                return;
              }
              setError(null);
              setTeamName(e.target.value);
            }}
            required
          />
        </NameContainer>
        <NameContainer hasError={Boolean(error)}>
          <NameLabel htmlFor="league-password">{"League Password"}</NameLabel>
          <NameInput
            id="league-password"
            value={leaguePassword}
            onChange={(e) => {
              if (e.target.value.length > 256) {
                return;
              }
              setError(null);
              setLeaguePassword(e.target.value);
            }}
            required
          />
        </NameContainer>
        <StyledLink href="/fantasy/leagues/create">{"Create a New League"}</StyledLink>
        <SubmitContainer>
          <Submit
            type="submit"
            value={"Join League"}
            disabled={!canSubmit}
          />
        </SubmitContainer>
      </Form>
    </Page>
  );
};

export default LeagueJoin;
