import React from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../../helpers/styles";
import type LeagueModel from "../../../../../api/LeagueModel";
import Alert from "../../../../../helpers/alerts";
import Checkbox from "../../../../../components/Checkbox";
import { useCompetitorStore, useEventPickStore, useEventStore, useLeagueMemberStore, useRosterCompetitorStore } from "../../../../../stores";
import User from "../../../../../api/User";
import Icon, { ICON_TYPES } from "../../../../../components/Icon";
import type EventCompetitorModel from "../../../../../api/EventCompetitorModel";

const SharedCellStyles = css`
  padding: 10px;
  border-top: 2px solid #b7c1ff;
  border-bottom: 2px solid #b7c1ff;
  background-color: #1d2a70;
  &:last-child {
    border-right: 2px solid #b7c1ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:first-child {
    border-left: 2px solid #b7c1ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const MainCell = styled.td`
  color: ${Styles.colors.font};
  ${SharedCellStyles}
`;

const CheckCell = styled.td`
  width: 80px;
  ${SharedCellStyles}
`;

const HeaderCell = styled.th`
  color: ${Styles.colors.font};
  text-align: left;
  padding: 10px;
`;

interface RowProps {
  checked?: boolean
}

const CheckedRowStyle = css`
  > td {
    border-color: #0c9c29;

    &:first-child {
      border-left-color: #0c9c29;
    }

    &:last-child {
      border-right-color: #0c9c29;
    }
  }
`;

const SaveRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  color: ${Styles.colors.font};

  > .icon {
    display: flex;
    fill: ${Styles.colors.green};
    stroke: ${Styles.colors.green};

    &.saving {
      fill: ${Styles.colors.font};
      stroke: ${Styles.colors.font};
    }
  }
`;

const CompetitorRow = styled.tr<RowProps>`
  cursor: pointer;
  &:hover > td {
    background-color: #223182;
  }
  ${({ checked }) => checked ? CheckedRowStyle : ''}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
`;

interface Props {
  league: LeagueModel
  eventId: string
}

const YourPicks: React.FC<Props> = ({ league, eventId }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [eventPicks, setEventPicks, removeEventPick, addEventPick] = useEventPickStore((state) => [
    state.eventPicks,
    state.setEventPicks,
    state.removeEventPick,
    state.addEventPick
  ]);
  const [eventCompetitors, setEventCompetitors] = React.useState<Record<string, EventCompetitorModel>>({});
  const leagueMember = useLeagueMemberStore((state) => state.getLeagueMemberByUserId(league.id, User.user?.id));
  const rosterCompetitors = useRosterCompetitorStore((state) => {
    if (!leagueMember) {
      return [];
    }
    return state.getRosterCompetitorsByLeagueMember(leagueMember.id);
  });
  const getEvent = useEventStore((state) => state.getEvent);
  const event = getEvent(eventId);

  const getCompetitor = useCompetitorStore((state) => state.getCompetitor);

  React.useEffect(() => {
    const loadPicks = async (): Promise<any> => {
      setEventPicks({});
      const loadedPicks = await league.getPicks(eventId);
      setEventPicks(loadedPicks);
    };

    loadPicks();
  }, [league, eventId]);

  React.useEffect(() => {
    const loadEventCompetitors = async (): Promise<any> => {
      const eventCompetitors = await event.getEventCompetitors();
      setEventCompetitors(eventCompetitors);
    };

    if (event) {
      loadEventCompetitors();
    }
  }, [event]);

  const sortedRosterCompetitors = React.useMemo(() => {
    const sorted = Array.from(rosterCompetitors);
    sorted.sort((a, b) => {
      const isAPlaying = Object.values(eventCompetitors).some((ec) => ec.competitorId === a.competitorId);
      const isBPlaying = Object.values(eventCompetitors).some((ec) => ec.competitorId === b.competitorId);
      if (isAPlaying && !isBPlaying) {
        return -1;
      } else if (!isAPlaying && isBPlaying) {
        return 1;
      }

      const competitorA = getCompetitor(a.competitorId);
      const competitorB = getCompetitor(b.competitorId);

      return competitorB.rating - competitorA.rating;
    });

    return sorted;
  }, [getCompetitor, rosterCompetitors]);

  const roster = sortedRosterCompetitors.map((rc) => {
    const activePick = Object.values(eventPicks).find((pick) => {
      return pick.rosterCompetitorId === rc.id;
    });
    const checked = Boolean(activePick);
    const onClick = async (): Promise<any> => {
      try {
        setIsSaving(true);
        if (activePick) {
          await league.deletePick(eventId, rc.id);
          removeEventPick(activePick.id);
        } else {
          if (Object.keys(eventPicks).length >= league.pickCount) {
            Alert.addAlert(`You already have ${league.pickCount} picks!`);
          } else {
            const newPick = await league.makePick(eventId, rc.id);
            addEventPick(newPick);
          }
        }
      } finally {
        setIsSaving(false);
      }
    };

    const competitor = getCompetitor(rc.competitorId);

    const isPlaying = Object.values(eventCompetitors).some((ec) => ec.competitorId === rc.competitorId);

    const isPlayingString = isPlaying ? 'Yes' : 'No';

    return (
      <CompetitorRow key={rc.id} checked={checked} onClick={onClick}>
        <CheckCell>
          <Checkbox
            id={`self-pick-${rc.id}`}
            checked={checked}
          />
        </CheckCell>
        <MainCell>{competitor.name}</MainCell>
        <MainCell>{competitor.rating}</MainCell>
        <MainCell>{isPlayingString}</MainCell>
      </CompetitorRow>
    );
  });

  const saveRow = (
    <SaveRow>
      <Icon className={isSaving ? 'saving' : ''} type={ICON_TYPES.CIRCLE_CHECK} spinning={isSaving} size={15} />
      {isSaving ? "Saving picks..." : "Your picks were auto-saved"}
    </SaveRow>
  );
  return (
    <>
      {saveRow}
      <Table>
        <thead>
          <tr>
            <HeaderCell>{`${Object.keys(eventPicks).length}/${league.pickCount} Picks`}</HeaderCell>
            <HeaderCell>{"Name"}</HeaderCell>
            <HeaderCell>{"Rating"}</HeaderCell>
            <HeaderCell>{"Registered"}</HeaderCell>
          </tr>
        </thead>
        <tbody>{roster}</tbody>
      </Table>
    </>
  );
};

export default YourPicks;
