import React from "react";
import Admin from "../../../api/Admin";
import GenericTable from "../fantasy/league/GenericTable";
import styled from "styled-components";
import Popup from "../../../components/Popup";

interface AdminEvent {
  name: string
  id: string
  status: string
  endDate: string
}

const ActionButton = styled.button`
  cursor: pointer;
`;

const AdminHeader = styled.h2`
  color: var(--color-white);
`;

interface EventsTableProps {
  events: AdminEvent[]
}

const EventsTable: React.FC<EventsTableProps> = ({ events }) => {
  const headers = [
    "Name",
    "Status",
    "Action",
    "Refresh"
  ];

  const sortedEvents = React.useMemo(() => {
    if (!events) {
      return [];
    }

    const today = new Date();
    const eventsCopy = Array.from(events);
    eventsCopy.sort((a, b) => {
      const aEndDate = new Date(a.endDate);
      const bEndDate = new Date(b.endDate);
      if (aEndDate < today && bEndDate >= today) {
        return 1;
      } else if (aEndDate >= today && bEndDate < today) {
        return -1;
      }

      return aEndDate < bEndDate ? -1 : 1;
    });

    return eventsCopy;
  }, [JSON.stringify(events)]);

  const rows = React.useMemo(() => {
    return sortedEvents.map((e) => {
      const startEvent = (): any => Admin.startEvent(e.id);
      const finishEvent = (): any => Admin.finishEvent(e.id);
      const refreshEvent = (): any => Admin.refreshEvent(e.id);

      let button: React.ReactNode;
      switch (e.status) {
        case 'unstarted':
          button = <ActionButton key="start" onClick={startEvent}>{'Start'}</ActionButton>;
          break;
        case 'in_progress':
          button = <ActionButton key="finish" onClick={finishEvent}>{'Finish'}</ActionButton>;
          break;
        default:
          button = '-';
      }

      const refreshButton = (
        <ActionButton key="refresh" onClick={refreshEvent}>{'Refresh'}</ActionButton>
      );

      return {
        cells: [
          e.name,
          e.status,
          button,
          refreshButton
        ],
        key: e.id
      };
    });
  }, [sortedEvents]);
  return (
    <GenericTable
      headers={headers}
      rows={rows}
    />
  );
};

const AdminEvents: React.FC = () => {
  const [events, setEvents] = React.useState<AdminEvent[]>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const [name, setName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [pdgaEventId, setPdgaEventId] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [tier, setTier] = React.useState("");
  const [presentedBy, setPresentedBy] = React.useState("");

  const canSubmit = Boolean(
    name && location && pdgaEventId && startDate && endDate && !isSaving
  );

  const addEvent = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();
    if (!canSubmit) {
      return;
    }

    setIsSaving(true);
    try {
      const newEvent = await Admin.createEvent({
        name,
        location,
        startDate,
        endDate,
        pdgaEventId,
        tier,
        presentedBy
      });
      console.log(newEvent);
    } finally {
      setIsSaving(false);
    }
  };

  React.useEffect(() => {
    const loadEvents = async (): Promise<any> => {
      const readEvents = await Admin.getEvents();
      setEvents(readEvents as AdminEvent[]);
    };
    loadEvents();
  }, []);

  const today = new Date();

  const upcomingEvents = Object.values(events).filter((event) => {
    return new Date(event.endDate) >= today;
  });

  const pastEvents = Object.values(events).filter((event) => {
    return new Date(event.endDate) < today;
  });

  const addPopupContent = (
    <div className="add-popup-content">
      <h2>Add Event</h2>
      <form className="add-event-form" onSubmit={addEvent}>
        <label htmlFor="name">Event Name</label>
        <input
          disabled={isSaving}
          id="name"
          className="name-input"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <label htmlFor="presented-by">Event Presented By</label>
        <input
          disabled={isSaving}
          id="presented-by"
          className="presented-by-input"
          value={presentedBy}
          onChange={(e) => {
            setPresentedBy(e.target.value);
          }}
        />
        <label htmlFor="location">Location</label>
        <input
          disabled={isSaving}
          id="location"
          className="location-input"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
        <label htmlFor="tier">Event Tier</label>
        <input
          disabled={isSaving}
          id="tier"
          className="tier-input"
          value={tier}
          onChange={(e) => {
            setTier(e.target.value);
          }}
        />
        <label htmlFor="pdga-event-id">PDGA Event ID</label>
        <input
          disabled={isSaving}
          id="pdga-event-id"
          className="pdga-event-id-input"
          value={pdgaEventId}
          onChange={(e) => {
            setPdgaEventId(e.target.value);
          }}
        />
        <label htmlFor="start-date">Start Date</label>
        <input
          disabled={isSaving}
          id="start-date"
          className="start-date-input"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          type="datetime-local"
        />
        <label htmlFor="end-date">End Date</label>
        <input
          disabled={isSaving}
          id="end-date"
          className="end-date-input"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          type="datetime-local"
        />
        <input
          disabled={!canSubmit}
          type="submit"
          value={"Create Event"}
        />
      </form>
    </div>
  );

  const addPopup = showAdd ? (
    <Popup
      content={addPopupContent}
      onClose={() => setShowAdd(false)}
    />
  ) : null;

  return (
    <div>
      {addPopup}
      <button onClick={() => setShowAdd(true)}>{'Add Event'}</button>
      <AdminHeader>{"Current/Upcoming Events"}</AdminHeader>
      <EventsTable events={upcomingEvents} />
      <AdminHeader>{"Past Events"}</AdminHeader>
      <EventsTable events={pastEvents} />
    </div>
  );
};

export default AdminEvents;
