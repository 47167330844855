import React from "react";

import styled from "styled-components";
import type LeagueModel from "../../../../api/LeagueModel";
import { useLeagueMemberStore } from "../../../../stores";
import Roster from "./Roster";

interface RosterProps {
  league: LeagueModel
}

const RostersContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const Rosters: React.FC<RosterProps> = ({ league }) => {
  const leagueMembers = useLeagueMemberStore((state) => state.getLeagueMembersByLeague(league.id));

  const rosterComponents = leagueMembers.map((leagueMember) => (
    <Roster
      key={leagueMember.id}
      league={league}
      leagueMember={leagueMember}
    />
  ));

  return (
    <RostersContainer>
      {rosterComponents}
    </RostersContainer>
  );
};

export default Rosters;
