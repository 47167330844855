import React from 'react';

import Icon, { ICON_TYPES } from '../../../components/Icon';
import Link from '../../../components/Link';
import Popup from '../../../components/Popup';
import type EventModel from '../../../api/EventModel';

interface Props {
  discGolfEvent: EventModel
}

const EventCard: React.FC<Props> = ({ discGolfEvent }) => {
  if (!discGolfEvent) {
    return null;
  }

  const [showPoints, setShowPoints] = React.useState(false);

  const futureEvent = discGolfEvent.isUpcomingEvent;
  const startDate = new Date(discGolfEvent.startDate);
  const endDate = new Date(discGolfEvent.endDate);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  const startFormatted = startDate.toLocaleString('en-US', { day: 'numeric', month: 'long' });
  const endFormatted = endDate.toLocaleString('en-US', { day: 'numeric', month: 'long' });
  const pickByDate = startDate.toLocaleString('en-US', options);

  const scoringLine = (
    <div className="points-info" onClick={() => setShowPoints(true)}>
      <Icon size={15} type={ICON_TYPES.CIRCLE_QUESTION} />How are points awarded?
    </div>
  );

  const pointsPopupContent = (
    <div className="points-popup-content">
      <h3>{'Points Info'}</h3>
      <div>{`Points are awarded in a manner similar to prize payouts. More points is better, and the top 40% of players get points.`}</div>
      <div>{'First place gets 100 points, and then fewer and fewer points for the top 40%.'}</div>
      <div>{'To view the actual formula please check out '}<Link href="/faq?question=points-calculation">{'our FAQ'}</Link>{'.'}</div>
    </div>
  );

  const pointsPopup = showPoints ? (
    <Popup
      content={pointsPopupContent}
      onClose={() => {
        setShowPoints(false);
      }}
    />
  ) : null;

  return (
    <div className="event-card">
      {pointsPopup}
      <div className="event-info">
        <h1>{discGolfEvent.name}</h1>
        <hr className="divider" />
        <h2>{`${startFormatted} - ${endFormatted}`}</h2>
        <h3>{discGolfEvent.location}</h3>
      </div>
      {scoringLine}
      {<div className="event-actions">
        {futureEvent && <span>{`Must make picks by ${pickByDate}`}</span>}
      </div>}
    </div>
  );
};

export default EventCard;
