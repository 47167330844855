import React from "react";
import singletons from "../api/singletons";
import switchPath from "../helpers/switchPath";

const EmailVerified: React.FC = () => {
  React.useEffect(() => {
    const refresh = async (): Promise<any> => {
      try {
        await singletons.auth?.currentUser?.reload();
      } finally {
        switchPath('/');
      }
    };

    refresh();
  }, []);
  return (<div></div>);
};

export default EmailVerified;
