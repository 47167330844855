export interface AlertType {
  message: string
  color?: string
  timer: number
  icon?: string
}

type ColorArg = { color?: string, icon?: string } | undefined;

const Alert: {
  alerts: AlertType[]
  addAlert: (alertMessage: string, colorArg?: ColorArg) => void
  removeAlert: (alertMessage: string) => void
  onAlertChange: null | ((alerts: AlertType[]) => void)
} = {
  alerts: [],

  addAlert: (alertMessage: string, { color, icon }: { color?: string, icon?: string } = {}) => {
    const timer = window.setTimeout(() => {
      Alert.removeAlert(alertMessage);
    }, 5000);
    Alert.alerts.push({
      message: alertMessage,
      color,
      timer,
      icon
    });

    if (Alert.onAlertChange) {
      Alert.onAlertChange(Alert.alerts);
    }
  },

  removeAlert: (alertMessage) => {
    const alertIndex = Alert.alerts.findIndex((alert) => alert.message === alertMessage);

    if (alertIndex === -1) {
      return;
    }
    const alert = Alert.alerts[alertIndex];
    Alert.alerts.splice(alertIndex, 1);
    window.clearTimeout(alert.timer);

    if (Alert.onAlertChange) {
      Alert.onAlertChange(Alert.alerts);
    }
  },

  onAlertChange: null
};

export default Alert;
