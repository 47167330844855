import React from "react";
import { type DiscleGuess } from "../../api/Discle";
import CompetitorGuessRow from "./CompetitorGuessRow";
import EmptyGuessRow from "./EmptyGuessRow";
import Styles from "../../helpers/styles";
import styled from "styled-components";

interface Props {
  guesses: DiscleGuess[]
  isDone: boolean
}

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0 15px;
`;

const TableHeader = styled.th`
  padding: 15px;
  @media (max-width: ${Styles.mobileBreakpoint}) {
    padding: 5px;
  }
  text-align: left;
  border-bottom: 2px solid #f3f2ff;

  overflow: hidden;
  text-overflow: ellipsis;

  color: #f3f2ff;

  &:not(:first-child) {
    text-align: center;
  }
`;

const NameHeader = styled(TableHeader)`
  @media (max-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const GuessesTable: React.FC<Props> = ({ guesses, isDone }) => {
  const guessRows: React.ReactNode[] = [];

  for (let i = 0; i < 8; i++) {
    if (guesses[i]) {
      guessRows.push(<CompetitorGuessRow key={`guess-row-${guesses[i].id}`} guess={guesses[i]} />);
    } else {
      guessRows.push(<EmptyGuessRow key={`empty-row-${i + 1}`} isDone={isDone} rowNumber={i + 1} />);
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <NameHeader>Name</NameHeader>
          <TableHeader>{"Sponsor"}</TableHeader>
          <TableHeader>{"Rating"}</TableHeader>
          <TableHeader>{"Division"}</TableHeader>
          <TableHeader>{"PDGA Number"}</TableHeader>
        </tr>
      </thead>
      <tbody>
        {guessRows}
      </tbody>
    </Table>
  );
};

export default GuessesTable;
