import { create } from 'zustand';
import type RosterCompetitorModel from '../api/RosterCompetitorModel';

interface RosterCompetitorState {
  // put roster competitors under their league member id
  rosterCompetitors: Record<string, Record<string, RosterCompetitorModel>>
  addRosterCompetitor: (rosterCompetitor: RosterCompetitorModel) => void
  removeRosterCompetitor: (rosterCompetitor: RosterCompetitorModel) => void
  addRosterCompetitors: (rosterCompetitors: RosterCompetitorModel[]) => void
  getRosterCompetitorsByLeagueMember: (leagueMemberId: string) => RosterCompetitorModel[]
}

const useRosterCompetitorStore = create<RosterCompetitorState>((set, get) => ({
  rosterCompetitors: {},
  addRosterCompetitor: (addedRosterCompetitor) => set(({ rosterCompetitors }) => {
    const { leagueMemberId } = addedRosterCompetitor;
    if (!rosterCompetitors[leagueMemberId]) {
      rosterCompetitors[leagueMemberId] = {};
    }

    rosterCompetitors[leagueMemberId][addedRosterCompetitor.id] = addedRosterCompetitor;
    return rosterCompetitors;
  }),
  removeRosterCompetitor: (removedRosterCompetitor) => set(({ rosterCompetitors }) => {
    const { leagueMemberId } = removedRosterCompetitor;
    if (rosterCompetitors[leagueMemberId]) {
      delete rosterCompetitors[leagueMemberId][removedRosterCompetitor.id];
    }

    return rosterCompetitors;
  }),
  addRosterCompetitors: (addedRosterCompetitors) => set(({ rosterCompetitors }) => {
    addedRosterCompetitors.forEach((rosterCompetitor) => {
      const leagueMemberId = rosterCompetitor.leagueMemberId;

      if (!rosterCompetitors[leagueMemberId]) {
        rosterCompetitors[leagueMemberId] = {};
      }

      rosterCompetitors[leagueMemberId][rosterCompetitor.id] = rosterCompetitor;
    });

    return rosterCompetitors;
  }),
  getRosterCompetitorsByLeagueMember: (leagueMemberId: string) => (
    Object.values(get().rosterCompetitors[leagueMemberId] ?? {})
  )
}));

export default useRosterCompetitorStore;
