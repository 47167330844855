import React from "react";
import classNames from "../helpers/classNames";

const Pencil = (): React.ReactElement => (
  <g>
    <path d="M3.548,20.921c4.927,0,9.853,0,14.78,0c0.708,0,1.416,0,2.124,0c0.643,0,0.645-1,0-1 c-4.927,0-9.853,0-14.78,0c-0.708,0-1.416,0-2.124,0C2.905,19.921,2.904,20.921,3.548,20.921L3.548,20.921z"/>
    <path d="M9.71,17.164c0.42-0.12,0.81-0.34,1.12-0.65l9.54-9.54c0.68-0.68,0.68-1.79,0-2.47l-0.94-0.93 c-0.66-0.66-1.81-0.66-2.47,0l-9.54,9.53c-0.31,0.31-0.53,0.7-0.64,1.12l-0.74,2.76c-0.07,0.26,0,0.53,0.19,0.72 c0.14,0.14,0.34,0.22,0.53,0.22L9.71,17.164z M10.12,15.804c-0.18,0.19-0.42,0.32-0.67,0.39l-0.97,0.26l-1-1l0.26-0.97 c0.07-0.25,0.2-0.48,0.39-0.67l0.38-0.37l1.99,1.99L10.12,15.804z M11.21,14.724l-1.99-1.99l6.73-6.73l1.99,1.99L11.21,14.724z M19.66,6.274l-1.01,1.01l-1.99-1.99l1.01-1.02c0.14-0.14,0.33-0.22,0.53-0.22s0.39,0.08,0.53,0.22l0.93,0.94 C19.95,5.504,19.95,5.974,19.66,6.274z"/>
  </g>
);

const CircleCheck = (): React.ReactElement => (
  <g>
    <path d="M15.811,10.399c0.45-0.46-0.25-1.17-0.71-0.71l-3.56,3.56c-0.58-0.58-1.16-1.16-1.73-1.73 c-0.46-0.46-1.17,0.25-0.71,0.71l2.08,2.08c0.2,0.19,0.52,0.19,0.71,0L15.811,10.399z"/>
    <path d="M12,21.933c-5.478,0-9.934-4.456-9.934-9.933S6.522,2.067,12,2.067S21.934,6.523,21.934,12 S17.478,21.933,12,21.933z M12,3.067c-4.926,0-8.934,4.007-8.934,8.933S7.074,20.933,12,20.933s8.934-4.007,8.934-8.933 S16.926,3.067,12,3.067z"/>
  </g>
);

const CircleQuestion = (): React.ReactElement => (
  <g>
    <g>
      <path d="M11.41,12.462c0.08-0.241,0.193-0.445,0.341-0.598c0.148-0.153,0.327-0.291,0.535-0.417
            c0.134-0.085,0.255-0.18,0.363-0.285c0.107-0.106,0.193-0.226,0.255-0.363c0.062-0.136,0.095-0.288,0.095-0.454
            c0-0.199-0.047-0.372-0.142-0.518c-0.094-0.146-0.218-0.258-0.374-0.338c-0.156-0.079-0.329-0.119-0.519-0.119
            c-0.173,0-0.337,0.036-0.495,0.107c-0.157,0.072-0.287,0.183-0.389,0.335c-0.046,0.067-0.083,0.141-0.111,0.224
            c-0.072,0.21-0.261,0.359-0.483,0.359h0c-0.349,0-0.601-0.348-0.479-0.675c0.047-0.125,0.106-0.241,0.179-0.348
            c0.188-0.279,0.437-0.491,0.748-0.634c0.311-0.143,0.654-0.216,1.031-0.216c0.411,0,0.773,0.077,1.082,0.231
            c0.31,0.154,0.55,0.367,0.721,0.642c0.172,0.274,0.258,0.595,0.258,0.96c0,0.251-0.039,0.477-0.118,0.678s-0.19,0.379-0.334,0.536
            c-0.144,0.157-0.318,0.296-0.52,0.417c-0.191,0.119-0.344,0.242-0.462,0.37s-0.207,0.278-0.257,0.454
            c-0.015,0.053-0.031,0.164-0.045,0.283c-0.029,0.245-0.236,0.429-0.482,0.429h0c-0.286,0-0.512-0.246-0.484-0.531
            C11.343,12.789,11.371,12.58,11.41,12.462z"/>
      <circle cx="11.792" cy="14.894" r="0.587"/>
    </g>
    <path d="M12,21.933c-5.477,0-9.933-4.456-9.933-9.934c0-5.477,4.456-9.933,9.933-9.933
        S21.933,6.523,21.933,12C21.933,17.477,17.477,21.933,12,21.933z M12,3.067c-4.926,0-8.933,4.007-8.933,8.933
        S7.074,20.933,12,20.933s8.933-4.008,8.933-8.934S16.926,3.067,12,3.067z"/>
  </g>
);

const Lock = (): React.ReactElement => (
  <g>
    <path d="M17.44,9.33h-1.1V6.4c0-2.39-1.95-4.34-4.34-4.34S7.66,4.01,7.66,6.4v2.93h-1.1 c-1.38,0-2.5,1.12-2.5,2.5v7.61c0,1.37,1.12,2.5,2.5,2.5h10.88c1.38,0,2.5-1.13,2.5-2.5v-7.61C19.94,10.45,18.82,9.33,17.44,9.33z M8.66,6.4c0-1.84,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34v2.93H8.66V6.4z M18.94,19.44c0,0.82-0.68,1.5-1.5,1.5H6.56 c-0.82,0-1.5-0.68-1.5-1.5v-7.61c0-0.83,0.68-1.5,1.5-1.5h10.88c0.82,0,1.5,0.67,1.5,1.5V19.44z"/>
    <path d="M13,14.95c0,0.37-0.2,0.69-0.5,0.86v1.5c0,0.28-0.23,0.49-0.5,0.5s-0.5-0.24-0.5-0.5v-1.5 c-0.3-0.17-0.5-0.49-0.5-0.86c0-0.55,0.45-1,1-1S13,14.4,13,14.95z"/>
  </g>
);

const Refresh = (): React.ReactElement => (
  <g>
    <path d="M4.061,13.67c0.26,2.74,1.92,5.23,4.4,6.45c2.45,1.2,5.43,1.07,7.74-0.38 c2.34-1.46,3.74-4.02,3.77-6.77c0-0.64-1-0.64-1,0c-0.03,2.74-1.62,5.25-4.13,6.37c-2.23,1-4.88,0.73-6.87-0.67 c-2.03-1.44-3.15-3.92-2.91-6.4c0.24-2.39,1.76-4.51,3.91-5.57c1.48-0.72,3.05-0.7,4.64-0.69v1.45c0,0.41,0.48,0.65,0.81,0.39 l2.47-1.95c0.26-0.21,0.25-0.59,0-0.79l-2.49-1.94c-0.33-0.25-0.81-0.02-0.8,0.4v1.44c-0.71-0.01-1.43-0.03-2.13,0.02 c-1.54,0.11-3.03,0.65-4.26,1.59C5.041,8.26,3.811,10.96,4.061,13.67z"/>
  </g>
);

const DownCircle = (): React.ReactElement => (
  <g>
    <path d="M12.35,13.85c-0.18,0.2-0.52,0.19-0.7,0l-3-3c-0.2-0.19-0.19-0.5,0-0.7c0.18-0.2,0.52-0.19,0.7,0 L12,12.79l2.65-2.64c0.19-0.2,0.5-0.19,0.7,0c0.2,0.18,0.19,0.52,0,0.7L12.35,13.85z"/>
    <path d="M21.933,12c0,5.477-4.456,9.933-9.933,9.933S2.067,17.477,2.067,12S6.523,2.067,12,2.067 S21.933,6.523,21.933,12z M3.067,12c0,4.926,4.007,8.933,8.933,8.933s8.933-4.007,8.933-8.933S16.926,3.067,12,3.067 S3.067,7.074,3.067,12z"/>
  </g>
);

const UpCircle = (): React.ReactElement => (
  <g>
    <path d="M11.65,10.15c0.18-0.2,0.52-0.19,0.7,0l3,3c0.2,0.19,0.19,0.5,0,0.7c-0.18,0.2-0.52,0.19-0.7,0
        L12,11.21l-2.65,2.64c-0.19,0.2-0.5,0.19-0.7,0c-0.2-0.18-0.19-0.52,0-0.7L11.65,10.15z"></path>
    <path d="M2.067,12c0-5.477,4.456-9.933,9.933-9.933S21.933,6.523,21.933,12S17.477,21.933,12,21.933
        S2.067,17.477,2.067,12z M20.933,12c0-4.926-4.007-8.933-8.933-8.933S3.067,7.074,3.067,12S7.074,20.933,12,20.933
        S20.933,16.926,20.933,12z"></path>
  </g>
);

const Logo = (): React.ReactElement => (
  <g>
    <ellipse className="disc" style={{ fill: 'none', strokeWidth: '10px' }} cx="258.464" cy="247.94" rx="151.264" ry="151.264"/>
    <path className="k" d="M -277.391 -99.025 L -277.391 -398.667 L -237.738 -398.667 L -237.738 -250.073 L -88.939 -398.667 L -35.183 -398.667 L -160.886 -277.257 L -29.665 -99.025 L -81.99 -99.025 L -188.684 -250.686 L -237.738 -202.857 L -237.738 -99.025 Z" transform="matrix(-1, 0, 0, -1, 0, 0)" style={{ whiteSpace: 'pre' }}/>
  </g>
);

const Flag = (): React.ReactElement => (
  <g>
    <path d="M20.565,3.18a.809.809,0,0,0-.81-.02l-1.13.56c-1.63.87-3.82.83-6.5-.13a9.141,9.141,0,0,0-7.3.52l-.76.41V3.56a.5.5,0,0,0-1,0V20.44a.5.5,0,0,0,1,0V15.9a.836.836,0,0,0,.2-.08l1.03-.55a8.163,8.163,0,0,1,6.5-.46c2.95,1.06,5.41,1.08,7.3.07l1.44-.72a.759.759,0,0,0,.4-.66V3.82A.751.751,0,0,0,20.565,3.18Zm-.63,10.16-1.31.66c-1.63.87-3.82.83-6.5-.13a9.141,9.141,0,0,0-7.3.52l-.76.4V5.65L5.3,4.99a8.122,8.122,0,0,1,6.5-.46c2.95,1.06,5.41,1.08,7.29.08l.85-.43Z"/>
  </g>
);

const CirclePlus = (): React.ReactElement => (
  <g>
    <path d="M15.001,12.501h-2.5v2.5c0,0.64-1,0.64-1,0v-2.5h-2.5c-0.65,0-0.64-1,0-1h2.5v-2.5 c0-0.64,1-0.64,1,0v2.5h2.5C15.641,11.501,15.641,12.501,15.001,12.501z"/>
    <path d="M12,21.933c-5.477,0-9.933-4.456-9.933-9.933S6.523,2.067,12,2.067S21.933,6.523,21.933,12 S17.477,21.933,12,21.933z M12,3.067c-4.926,0-8.933,4.007-8.933,8.933S7.074,20.933,12,20.933s8.933-4.007,8.933-8.933 S16.926,3.067,12,3.067z"/>
  </g>
);

const Power = (): React.ReactElement => (
  <g>
    <path d="M6.638,5.209a8.782,8.782,0,1,0,13.917,8.96,8.871,8.871,0,0,0-3.189-8.96c-.5-.39-1.214.312-.707.707a7.93,7.93,0,0,1,3.082,7.113,7.787,7.787,0,0,1-15.308.956A7.9,7.9,0,0,1,7.345,5.916c.507-.394-.205-1.1-.707-.707Z"/>
    <path d="M12.5,12.519a.5.5,0,0,1-1,0V3.548h0a.5.5,0,0,1,1,0Z"/>
  </g>
);

const Settings = (): React.ReactElement => (
  <g>
    <path d="M12.599,20.936h-1.301c-0.399,0-0.749-0.269-0.852-0.654l-0.774-2.833l-2.497,1.435
        c-0.345,0.196-0.78,0.14-1.06-0.139L5.19,17.827c-0.283-0.282-0.342-0.72-0.143-1.066l1.468-2.545L6.51,14.202l-2.787-0.747
        c-0.384-0.103-0.653-0.453-0.653-0.851v-1.303c0-0.397,0.268-0.747,0.651-0.851l2.839-0.777L5.121,7.171
        C4.924,6.826,4.982,6.389,5.262,6.109L6.18,5.191c0.279-0.28,0.716-0.34,1.061-0.142l2.552,1.464l0.012-0.004l0.746-2.791
        c0.102-0.385,0.452-0.654,0.851-0.654h1.302c0.399,0,0.749,0.269,0.852,0.655l0.763,2.838l2.508-1.441
        c0.344-0.197,0.778-0.139,1.06,0.139l0.925,0.918c0.284,0.282,0.342,0.721,0.142,1.067l-1.468,2.537l0.008,0.022l2.786,0.746
        c0.385,0.104,0.653,0.454,0.653,0.852v1.302c0,0.399-0.269,0.749-0.654,0.852l-2.836,0.774l1.439,2.505
        c0.198,0.345,0.14,0.782-0.142,1.063l-0.917,0.917c-0.282,0.281-0.718,0.338-1.063,0.141l-2.539-1.461l-0.022,0.009l-0.746,2.785
        C13.347,20.667,12.997,20.936,12.599,20.936z M11.388,19.936h1.12l0.738-2.755c0.072-0.268,0.269-0.489,0.528-0.592l0.134-0.052
        c0.249-0.099,0.526-0.078,0.761,0.057l2.509,1.444l0.789-0.789l-1.423-2.477c-0.136-0.236-0.155-0.528-0.048-0.781l0.052-0.125
        c0.105-0.252,0.318-0.438,0.584-0.51l2.799-0.749v-1.12l-2.755-0.738c-0.269-0.072-0.49-0.27-0.592-0.529l-0.051-0.131
        c-0.099-0.251-0.078-0.53,0.057-0.763l1.452-2.508l-0.796-0.791l-2.48,1.425c-0.237,0.136-0.518,0.155-0.772,0.052l-0.115-0.047
        c-0.252-0.102-0.447-0.322-0.518-0.588l-0.749-2.805h-1.119l-0.738,2.762C10.681,7.1,10.48,7.323,10.216,7.423l-0.12,0.045
        C9.85,7.563,9.575,7.542,9.345,7.41L6.823,5.962l-0.79,0.789l1.422,2.476c0.137,0.239,0.154,0.532,0.046,0.785L7.45,10.132
        c-0.105,0.247-0.317,0.431-0.579,0.502l-2.803,0.758v1.121l2.757,0.738c0.268,0.072,0.488,0.269,0.591,0.525l0.048,0.121
        c0.102,0.253,0.082,0.534-0.055,0.77l-1.451,2.515l0.796,0.791l2.47-1.419c0.242-0.138,0.535-0.156,0.787-0.045l0.106,0.044
        c0.256,0.098,0.453,0.319,0.525,0.59L11.388,19.936z M9.751,17.482c0.002,0.001,0.005,0.002,0.008,0.003L9.751,17.482z
        M9.605,17.421c0.002,0.001,0.004,0.002,0.007,0.003L9.605,17.421z M6.488,14.147C6.488,14.148,6.488,14.148,6.488,14.147
        L6.488,14.147z M6.532,9.736C6.532,9.736,6.532,9.737,6.532,9.736L6.532,9.736z M14.256,6.532L14.256,6.532
        C14.256,6.532,14.256,6.532,14.256,6.532z"/>
    <path d="M12,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.655,15,12,15z M12,10c-1.103,0-2,0.897-2,2
        s0.897,2,2,2s2-0.897,2-2S13.103,10,12,10z"/>
</g>
);

const CircleRight = (): React.ReactElement => (
  <g>
    <path d="M13.85,11.65c0.2,0.18,0.19,0.52,0,0.7l-3,3c-0.19,0.2-0.5,0.19-0.7,0c-0.2-0.18-0.19-0.52,0-0.7 L12.79,12l-2.64-2.65c-0.2-0.19-0.19-0.5,0-0.7c0.18-0.2,0.52-0.19,0.7,0L13.85,11.65z"/>
    <path d="M12,2.067c5.477,0,9.933,4.456,9.933,9.933S17.477,21.933,12,21.933S2.067,17.477,2.067,12
        S6.523,2.067,12,2.067z M12,20.933c4.926,0,8.933-4.007,8.933-8.933S16.926,3.067,12,3.067S3.067,7.074,3.067,12
        S7.074,20.933,12,20.933z"/>
  </g>
);

const Hamburger = (): React.ReactElement => (
  <g>
    <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
  </g>
);

const Home = (): React.ReactElement => (
  <g>
    <path d="M19.37,10.22l-6.2-7.6C12.88,2.27,12.46,2.06,12,2.06c-0.45,0-0.87,0.2-1.16,0.55l-6.21,7.61
                c-0.37,0.45-0.57,1.01-0.57,1.59v7.63c0,1.37,1.12,2.5,2.5,2.5h10.88c1.38,0,2.5-1.13,2.5-2.5v-7.63
                C19.94,11.23,19.74,10.67,19.37,10.22z M10,20.94v-5.5c0-0.83,0.67-1.5,1.5-1.5h1c0.83,0,1.5,0.67,1.5,1.5v5.5H10z M18.94,19.44
                c0,0.82-0.68,1.5-1.5,1.5H15v-5.5c0-1.38-1.12-2.5-2.5-2.5h-1c-1.38,0-2.5,1.12-2.5,2.5v5.5H6.56c-0.82,0-1.5-0.68-1.5-1.5v-7.63
                c0-0.35,0.12-0.69,0.34-0.95l6.22-7.61c0.09-0.12,0.23-0.19,0.38-0.19c0.16,0,0.3,0.07,0.39,0.19l6.21,7.61
                c0.22,0.26,0.34,0.6,0.34,0.95V19.44z"/>
  </g>
);

const Play = (): React.ReactElement => (
  <g>
    <path d="M6.562,21.94a2.5,2.5,0,0,1-2.5-2.5V4.56A2.5,2.5,0,0,1,7.978,2.5L18.855,9.939a2.5,2.5,0,0,1,0,4.12L7.977,21.5A2.5,2.5,0,0,1,6.562,21.94Zm0-18.884a1.494,1.494,0,0,0-.7.177,1.477,1.477,0,0,0-.8,1.327V19.439a1.5,1.5,0,0,0,2.35,1.235l10.877-7.44a1.5,1.5,0,0,0,0-2.471L7.413,3.326A1.491,1.491,0,0,0,6.564,3.056Z"/>
  </g>
);

const Disc = (): React.ReactElement => (
  <g>
    <path d="M385.5 30.38c-18.5.16-40.5 5.02-64.3 14.24C270.4 64.35 211.1 104 156.4 158.7c-54.8 54.7-94.46 114.1-114.12 164.9-9.89 25.4-14.7 48.6-14.28 67.9.43 19 5.8 33.8 16.05 44 10.26 10.3 24.96 15.6 44.06 16.1 19.19.4 42.39-4.4 67.89-14.3 50.8-19.7 110.1-59.4 164.8-114.1 54.7-54.7 94.4-114 114.1-164.9 9.9-25.4 14.7-48.6 14.3-67.79-.5-19.09-5.8-33.8-16.1-44.05-10.3-10.26-25-15.63-44-16.05-1.2-.03-2.4-.04-3.6-.03zm66.6 10.7c9.9 13.36 14.7 30.3 15.1 49.08.5 22.44-5 47.74-15.6 74.64-20.8 54-61.8 114.9-118 171.1-56.3 56.3-117.2 97.2-171.2 118.1-26.9 10.6-52.2 16-74.65 15.6-15.06-.3-28.93-3.5-40.72-9.8.8.9 1.62 1.8 2.47 2.6 25.95 26 72.3 29.4 128.3 10.7 56-18.6 120.3-59.2 178.2-117.2 57.9-57.9 98.5-122.1 117.1-178.1 18.8-56 15.3-102.35-10.7-128.3-3.1-3.14-6.6-5.94-10.3-8.42zM299.7 122.5c7.8 0 15.3 2.3 20.7 7.7 6.7 6.7 8.5 16.4 7.4 26.1-1 9.7-4.7 20.2-10.3 31.6-11.2 22.8-30.4 49-55.5 74.1-25.1 25.1-51.3 44.3-74.1 55.5-11.3 5.6-21.9 9.3-31.6 10.3-9.7 1.1-19.4-.7-26.1-7.4-6.7-6.7-8.5-16.4-7.4-26.1 1-9.7 4.7-20.3 10.3-31.7 11.2-22.8 30.4-48.9 55.5-74s51.3-44.4 74.1-55.6c11.4-5.6 21.9-9.2 31.6-10.2 1.8-.2 3.6-.3 5.4-.3z" />
  </g>
);

const CircleRemove = (): React.ReactElement => (
  <g>
      <path d="M9.525,13.765c-0.46,0.46,0.25,1.17,0.71,0.71c0.59-0.59,1.175-1.18,1.765-1.76l1.765,1.76
          c0.46,0.46,1.17-0.25,0.71-0.71c-0.59-0.58-1.18-1.175-1.76-1.765c0.41-0.42,0.82-0.825,1.23-1.235c0.18-0.18,0.35-0.36,0.53-0.53
          c0.45-0.46-0.25-1.17-0.71-0.71L12,11.293l-1.765-1.768c-0.46-0.45-1.17,0.25-0.71,0.71L11.293,12L9.525,13.765z"/>
      <path d="M12,21.933c-5.478,0-9.934-4.456-9.934-9.933S6.522,2.067,12,2.067S21.934,6.523,21.934,12
          S17.478,21.933,12,21.933z M12,3.067c-4.926,0-8.934,4.007-8.934,8.933S7.074,20.933,12,20.933s8.934-4.007,8.934-8.933
          S16.926,3.067,12,3.067z"/>
  </g>
);

const Search = (): React.ReactElement => (
  <g>
    <path d="M20.031,20.79c0.46,0.46,1.17-0.25,0.71-0.7l-3.75-3.76c1.27-1.41,2.04-3.27,2.04-5.31
                    c0-4.39-3.57-7.96-7.96-7.96s-7.96,3.57-7.96,7.96c0,4.39,3.57,7.96,7.96,7.96c1.98,0,3.81-0.73,5.21-1.94L20.031,20.79z
                    M4.11,11.02c0-3.84,3.13-6.96,6.96-6.96c3.84,0,6.96,3.12,6.96,6.96c0,3.84-3.12,6.96-6.96,6.96C7.24,17.98,4.11,14.86,4.11,11.02
                    z"/>
  </g>
);

const CircleLeft = (): React.ReactElement => (
  <g>
      <path d="M10.15,12.35c-0.2-0.18-0.19-0.52,0-0.7l3-3c0.19-0.2,0.5-0.19,0.7,0c0.2,0.18,0.19,0.52,0,0.7
          L11.21,12l2.64,2.65c0.2,0.19,0.19,0.5,0,0.7c-0.18,0.2-0.52,0.19-0.7,0L10.15,12.35z"></path>
      <path d="M12,21.933c-5.477,0-9.933-4.456-9.933-9.933S6.523,2.067,12,2.067S21.933,6.523,21.933,12
          S17.477,21.933,12,21.933z M12,3.067c-4.926,0-8.933,4.007-8.933,8.933S7.074,20.933,12,20.933s8.933-4.007,8.933-8.933
          S16.926,3.067,12,3.067z"></path>
  </g>
);

const Discord = (): React.ReactElement => (
  <g>
    <path d="M20.317 4.54101C18.7873 3.82774 17.147 3.30224 15.4319 3.00126C15.4007 2.99545 15.3695 3.00997 15.3534 3.039C15.1424 3.4203 14.9087 3.91774 14.7451 4.30873C12.9004 4.02808 11.0652 4.02808 9.25832 4.30873C9.09465 3.90905 8.85248 3.4203 8.64057 3.039C8.62448 3.01094 8.59328 2.99642 8.56205 3.00126C6.84791 3.30128 5.20756 3.82678 3.67693 4.54101C3.66368 4.54681 3.65233 4.5565 3.64479 4.56907C0.533392 9.29283 -0.31895 13.9005 0.0991801 18.451C0.101072 18.4733 0.11337 18.4946 0.130398 18.5081C2.18321 20.0401 4.17171 20.9701 6.12328 21.5866C6.15451 21.5963 6.18761 21.5847 6.20748 21.5585C6.66913 20.9179 7.08064 20.2424 7.43348 19.532C7.4543 19.4904 7.43442 19.441 7.39186 19.4246C6.73913 19.173 6.1176 18.8662 5.51973 18.5178C5.47244 18.4897 5.46865 18.421 5.51216 18.3881C5.63797 18.2923 5.76382 18.1926 5.88396 18.0919C5.90569 18.0736 5.93598 18.0697 5.96153 18.0813C9.88928 19.9036 14.1415 19.9036 18.023 18.0813C18.0485 18.0687 18.0788 18.0726 18.1015 18.091C18.2216 18.1916 18.3475 18.2923 18.4742 18.3881C18.5177 18.421 18.5149 18.4897 18.4676 18.5178C17.8697 18.8729 17.2482 19.173 16.5945 19.4236C16.552 19.4401 16.533 19.4904 16.5538 19.532C16.9143 20.2414 17.3258 20.9169 17.7789 21.5576C17.7978 21.5847 17.8319 21.5963 17.8631 21.5866C19.8241 20.9701 21.8126 20.0401 23.8654 18.5081C23.8834 18.4946 23.8948 18.4742 23.8967 18.452C24.3971 13.1911 23.0585 8.6212 20.3482 4.57004C20.3416 4.5565 20.3303 4.54681 20.317 4.54101ZM8.02002 15.6802C6.8375 15.6802 5.86313 14.577 5.86313 13.222C5.86313 11.8671 6.8186 10.7639 8.02002 10.7639C9.23087 10.7639 10.1958 11.8768 10.1769 13.222C10.1769 14.577 9.22141 15.6802 8.02002 15.6802ZM15.9947 15.6802C14.8123 15.6802 13.8379 14.577 13.8379 13.222C13.8379 11.8671 14.7933 10.7639 15.9947 10.7639C17.2056 10.7639 18.1705 11.8768 18.1516 13.222C18.1516 14.577 17.2056 15.6802 15.9947 15.6802Z"/>
  </g>
);

const Instagram = (): React.ReactElement => (
  <g>
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
  </g>
);

const CircleInfo = (): React.ReactElement => (
  <g>
      <g>
          <path d="M11.5,15.001c0,0.643,1,0.645,1,0l0,0v-4.018c0-0.643-1-0.645-1,0V15.001z"></path>
          <circle cx="12" cy="9.001" r="0.5"></circle>
      </g>
      <path d="M12,2.067c5.478,0,9.934,4.456,9.934,9.933S17.478,21.933,12,21.933S2.066,17.477,2.066,12
          S6.522,2.067,12,2.067z M12,20.933c4.926,0,8.934-4.007,8.934-8.933S16.926,3.067,12,3.067S3.066,7.074,3.066,12
          S7.074,20.933,12,20.933z"></path>
  </g>
);

export const ICON_TYPES = {
  CIRCLE_CHECK: 'circle-check',
  CIRCLE_DOWN: 'circle-down',
  CIRCLE_LEFT: 'circle-left',
  CIRCLE_UP: 'circle-up',
  CIRCLE_INFO: 'circle-info',
  CIRCLE_PLUS: 'circle-plus',
  CIRCLE_QUESTION: 'circle-question',
  CIRCLE_REMOVE: 'circle-remove',
  CIRCLE_RIGHT: 'circle-right',
  DISC: 'disc',
  DISCORD: 'discord',
  FLAG: 'flag',
  HAMBURGER: 'hamburger',
  HOME: 'home',
  INSTAGRAM: 'instagram',
  LOCK: 'lock',
  LOGO: 'logo',
  PENCIL: 'pencil',
  PLAY: 'play',
  POWER: 'power',
  REFRESH: 'refresh',
  SEARCH: 'search',
  SETTINGS: 'settings'
};

export const ICON_COLORS = {
  WHITE: 'color-white',
  BLACK: 'color-black',
  EMPHASIS: 'color-emphasis',
  LIGHT_BLACK: 'color-light-black',
  DISABLED: 'color-disabled',
  POSITIVE: 'color-positive'
};

interface IconProps {
  className?: string
  type: string
  size?: number
  onClick?: React.MouseEventHandler<HTMLDivElement>
  alt?: string
  spinning?: boolean
  color?: string
  defaultCursor?: boolean
}

const Icon: React.FC<IconProps> = ({ className, type, size, onClick, spinning, color = ICON_COLORS.WHITE, defaultCursor }) => {
  let icon: React.ReactElement | undefined;
  let vb = 24;
  let noStroke = false;
  if (type === ICON_TYPES.PENCIL) {
    icon = <Pencil />;
  } else if (type === ICON_TYPES.CIRCLE_CHECK) {
    icon = <CircleCheck />;
  } else if (type === ICON_TYPES.LOCK) {
    icon = <Lock />;
  } else if (type === ICON_TYPES.REFRESH) {
    icon = <Refresh />;
  } else if (type === ICON_TYPES.LOGO) {
    icon = <Logo />;
  } else if (type === ICON_TYPES.CIRCLE_DOWN) {
    icon = <DownCircle />;
  } else if (type === ICON_TYPES.CIRCLE_QUESTION) {
    icon = <CircleQuestion />;
  } else if (type === ICON_TYPES.FLAG) {
    icon = <Flag />;
  } else if (type === ICON_TYPES.CIRCLE_PLUS) {
    icon = <CirclePlus />;
  } else if (type === ICON_TYPES.POWER) {
    icon = <Power />;
  } else if (type === ICON_TYPES.SETTINGS) {
    icon = <Settings />;
  } else if (type === ICON_TYPES.CIRCLE_RIGHT) {
    icon = <CircleRight />;
  } else if (type === ICON_TYPES.HAMBURGER) {
    icon = <Hamburger />;
    vb = 32;
  } else if (type === ICON_TYPES.HOME) {
    icon = <Home />;
  } else if (type === ICON_TYPES.PLAY) {
    icon = <Play />;
  } else if (type === ICON_TYPES.DISC) {
    icon = <Disc />;
    vb = 512;
  } else if (type === ICON_TYPES.CIRCLE_REMOVE) {
    icon = <CircleRemove />;
  } else if (type === ICON_TYPES.SEARCH) {
    icon = <Search />;
  } else if (type === ICON_TYPES.CIRCLE_UP) {
    icon = <UpCircle />;
  } else if (type === ICON_TYPES.CIRCLE_LEFT) {
    icon = <CircleLeft />;
  } else if (type === ICON_TYPES.DISCORD) {
    icon = <Discord />;
    noStroke = true;
  } else if (type === ICON_TYPES.INSTAGRAM) {
    icon = <Instagram />;
    noStroke = true;
  } else if (type === ICON_TYPES.CIRCLE_INFO) {
    icon = <CircleInfo />;
  }

  const iconClasses = classNames('icon', className, color, {
    spinning,
    clickable: onClick,
    'default-cursor': defaultCursor,
    'no-stroke': noStroke
  });
  const svgClasses = classNames('icon-svg', {
    spinning,
    clickable: onClick
  });
  return (
    <div className={iconClasses} onClick={onClick}>
      <svg className={svgClasses} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${vb} ${vb}`}>
        {icon}
      </svg>
    </div>
  );
};

export default Icon;
